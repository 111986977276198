import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import useStore from '@/store/useStore';

export const useContinue = (emit: (event: 'close', ...args: unknown[]) => void) => {
  const store = useStore();

  const onContinue = () => {
    Promise.all([
      store.dispatch('tenants/fetchCurrentTenantInfo'),
      store.dispatch('tenants/fetchTenants'),
    ])
      .then(() => {
        emit('close');
        redirectToInitialRoute();
      })
      .catch(() => {});
  };

  return { onContinue };
};
