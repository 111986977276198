import {
  computed,
  ref,
  watch,
} from 'vue';
import debounce from 'lodash.debounce';

import useStore from '@/store/useStore';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';

export const useAppGlobalEntities = () => {
  const store = useStore();

  const isGlobalEntitiesLoading = ref(false);

  const isUserLoading = computed(() => store?.getters['user/userLoading']);
  const isTenantLoading = computed(() => store?.getters['tenants/tenantLoading']);
  const userTenants = computed(() => store?.getters['tenants/availableUserTenants']);

  const setGlobalEntitiesLoading = (isLoading: boolean) => {
    isGlobalEntitiesLoading.value = isLoading;
  };

  const setGlobalEntitiesLoadingWithDebounce = debounce(setGlobalEntitiesLoading, 100);

  const fetchUserAndTenants = () => store.dispatch('user/fetchUser')
    .then(() => {
      // Если oneTimePassword === true, то fetchTenant - отдаст 403, т.к. не будет доступа к tenants
      // чтобы не делать ненужного запроса и не ловить ошибку - добавим проверку на otp
      const isLocked = store.getters['user/currentUser']?.lockedSince;
      const isOTP = store.getters['user/currentUser']?.oneTimePassword;
      if (!isOTP && !isLocked) {
        return store.dispatch('tenants/fetchTenantsWithLoading');
      }
    })
    .catch(() => {});

  const fetchGlobalEntities = async () => fetchUserAndTenants()
    .catch((error) => {
      // TODO: удалить после решения проблемы с разлогином
      // eslint-disable-next-line
      console.log('[fetchGlobalEntities] fetchUserAndTenants().catch');
      store.dispatch(`app/${RESET_AUTH_STATE}`);
      throw error;
    });

  // Смотрим на изменение стейта загрузки пользователя и тенантов
  // Сетим isGlobalEntitiesLoading с debounce для того чтобы избежать рывков в анимации лоадера приложения
  watch(() => [isUserLoading.value, isTenantLoading.value], ([isUserLoading, isTenantLoading]: boolean[]) => {
    setGlobalEntitiesLoadingWithDebounce(isUserLoading || isTenantLoading);
  });

  return {
    userTenants,
    isGlobalEntitiesLoading,

    fetchGlobalEntities,
  };
};
