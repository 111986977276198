<template>
  <div class="date-picker-month-feed">
    <DatePickerMonth
      :month="selectedMonth"
      :year="selectedYear"
      :selectedDate="selectedDate"
      :minDate="minDate"
      :maxDate="maxDate"
    />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import { EMonth } from '@/ui/types/constants';

import DatePickerMonth from './DatePickerMonth/index.vue';

export default defineComponent({
  name: 'DatePickerMonthFeed',
  components: { DatePickerMonth },
  props: {
    selectedMonth: {
      type: Number as PropType<EMonth>,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    minDate: {
      type: String,
      required: true,
    },
    maxDate: {
      type: String,
      required: true,
    },
  },
  emits: [],
  setup(_) {
    return {};
  },
});
</script>

<style lang="scss" src="./styles.scss" />
