import tt from '@/i18n/utils/translateText';
import joinString from '@/utils/joinString';

export const ROUTE_PERMISSION_REJECTION_REASON = {
  underPaywall: 'underPaywall',
  unaccepted: 'unaccepted',
};

export enum EDayOfWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export enum ETimeUnit {
  millisecond = 'millisecond',
  milliseconds = 'milliseconds',
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  date = 'date',
}

export enum EControlAppearance {
  inline = 'inline',
  outline = 'outline',
}

export const enum EFederalDistrictCode {
  DVFO = 'DVFO',
  SZFO = 'SZFO',
  UFO = 'UFO',
  CFO = 'CFO',
  SFO = 'SFO',
  PFO = 'PFO',
  URFO = 'URFO',
  SKFO = 'SKFO',
}

export enum EPermissionComparisonFunction {
  OR = 'OR',
  AND = 'AND',
}

export enum EInequalitySymbol {
  less = 'less',
  greater = 'greater',
}

export enum EInputType {
  text = 'text',
  number = 'number',
  file = 'file',
}

export enum ENotificationChannel {
  email = 'email',
  webPush = 'webPush',
}

export enum ELayout {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export const DayOfWeekSequence = {
  [EDayOfWeek.monday]: 1,
  [EDayOfWeek.tuesday]: 2,
  [EDayOfWeek.wednesday]: 3,
  [EDayOfWeek.thursday]: 4,
  [EDayOfWeek.friday]: 5,
  [EDayOfWeek.saturday]: 6,
  [EDayOfWeek.sunday]: 7,
};

export const DayOfWeekLabelShort = {
  [EDayOfWeek.monday]: 'Пн',
  [EDayOfWeek.tuesday]: 'Вт',
  [EDayOfWeek.wednesday]: 'Ср',
  [EDayOfWeek.thursday]: 'Чт',
  [EDayOfWeek.friday]: 'Пт',
  [EDayOfWeek.saturday]: 'Сб',
  [EDayOfWeek.sunday]: 'Вс',
};

export const ROUTE_TYPE = {
  admin: 'admin',
  private: 'private',
  public: 'public',
  unauthenticated: 'unauthenticated',
};

export const START_RU_ALPHABET_IN_UNICODE = 1040;

export const ONE_SECOND = 1000;
export const ONE_MUNUTE = 60000;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export const MILLISECONDS_IN_HOUR = 3600000;
export const SECONDS_IN_DAY = 86400;
export const MILLISECONDS_IN_DAY = 86400000;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;

export const INCOTERMS = ['EXW', 'FCA', 'CPT', 'CIP', 'DAT', 'DAP', 'DDP', 'FAS', 'FOB', 'CFR', 'CIF', 'DDU', 'DAF', 'DEQ', 'DES'];

export const DEFAULT_PAGE_SIZES = [20, 50, 100];

export const REGEX_PATTERNS = {
  /** Допускает только целые числа и числа с запятой */
  onlyIntAndFloatNumbers: /^\d+(?:[.,]\d+)?$/,
  /** Допускает только целые числа */
  onlyIntNumbers: /^[0-9]*$/,
  /** Допускает только email */
  onlyEmail: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  /** Строка содержит буквы в верхнем и нижнем реестре */
  containsLowercaseAndUppercaseLetters: /(?=.*[a-zа-я])(?=.*[A-ZА-Я])/,
  /** Строка содержит хотя бы одну цифру */
  containsAtLeastOneDigit: /(?=.*\d)/,
  /** Российский номер телефона */
  russianPhoneNumber: /^(\+7|7)\d{10}$/g,
  /** Иностранный номер телефона */
  foreignPhoneNumber: /^[^7]\d{8,18}$/g,
  /** Допустит число больше нуля */
  biggerThanZero: /^[1-9]\d*$/,
  /** Допускает только целые числа и буквы кириллицы */
  onlyIntNumbersOrCyrillicLetters: /^[А-Яа-я0-9]+$/,
  /** HTML тэг */
  htmlTag: /(<([^>]+)>)/g,
  /** Escape-последовательности (амперсанд | левая угловая скобка | правая угловая скобка | парные кавычки | одинарные кавычки) */
  escapedHtml: /&(?:amp|lt|gt|quot|#(0+)?39);/g,
};

export const DEFAULT_EMAIL_RULE = {
  pattern: REGEX_PATTERNS.onlyEmail,
  message: tt('shared.rule.emailCorrect'),
};

export enum EViewState {
  idle = 'idle',
  loading = 'loading',
  done = 'done',
  withError = 'withError',
  notFound = 'notFound',
  forbidden = 'forbidden',
}

export enum EAppState {
  /** приложение инициализировано, запрос на проверку валидности токена еще не отправлен */
  initial = 'initial',
  /** процесс отправки запроса на проверку токена. выполняется после initial */
  checking = 'checking',
  /** отсутствие токена или после проверки валидности токена вернулся статус 401 */
  unauthenticated = 'unauthenticated',
  /** после проверки валидности токена вернулся статус 200, и пользователь не заблокирован */
  authenticated = 'authenticated',
  /** после авторизации пользователя, который не может пользоваться системой */
  interactionLocked = 'interactionLocked',
  /** технические работы */
  onMaintenance = 'onMaintenance',
}

export enum ETransitionMethod {
  onCheck = 'onCheck',
  onSuccessfulAuthentication = 'onSuccessfulAuthentication',
  onFailedAuthentication = 'onFailedAuthentication',
  onLogin = 'onLogin',
  onLogout = 'onLogout',
  onInteractionLock = 'onInteractionLock',
  onInteractionUnlock = 'onInteractionUnlock',
  onMaintenance = 'onMaintenance',
}

export enum EWasAuthBy {
  loginPassword = 'loginPassword',
  entryCode = 'entryCode',
  social = 'social',
}

export const API_ERROR_CODE = {
  // eslint-disable-next-line
  not_found: 'not_found',
};

export enum EUserSystemRole {
  admin = 'admin',
  support = 'support',
  user = 'user',
}

export const DATE_FORMAT = {
  hoursMinutes: 'HH:mm', // -> 16:37
  hoursMinutesTimezone: 'HH:mmZZ', // -> 16:37+0300
  hoursMinutesSeconds: 'HH:mm:ss', // -> 16:37:49
  fullDate: 'DD.MM.YYYY', // -> 13.08.2021
  fullDateWithShortYears: 'DD.MM.YY', // -> 13.08.21
  fullDateWithHoursMinutes: 'DD.MM.YYYY, HH:mm', // -> 13.08.2021, 15:19
  dayWithMonth: 'DD MMMM', // -> 18 февраля
  dayMonthYear: 'DD MMMM YYYY', // -> 18 февраля 2024
  monthYear: 'MM/YYYY', // -> 02/2024
  weekYear: 'wo/YYYY', // -> 16/2024 (16 неделя 2024 года)
  UTCformat: 'YYYY-MM-DD', // 2022-05-20
  UTCWithTimeFormat: 'YYYY-MM-DD HH:mm', // 2022-05-20 15:19
  ISOLocal: 'YYYY-MM-DDTHH:mm:ss', // 2023-07-05T10:50:00
  fullDateWithHoursMinutesSlashed: 'YYYY/MM/DD, HH:mm', // -> 2021/08/13 15:19
  ISODateTimeWithZone: 'YYYY-MM-DDTHH:mm:ssZ', // -> 2024-08-14T14:00:00+02:00
};

export const DATE_PICKER_TOP_LEFT_PLACEMENT = {
  points: ['bl', 'bl'],
  targetOffset: [0, 38],
};

export const NO_BREAK_SPACE = '\u00A0';

export enum EKeyboardKey {
  escape = 'Escape',
  enter = 'Enter',
  tab = 'Tab',
  arrowDown = 'ArrowDown',
  arrowUp = 'ArrowUp',
}

export const ANY_TYPE_OPTION = {
  id: 'any-type',
  name: 'Любой',
};

// https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export enum EUploadFileFormat {
  xls = '.xls',
  xlsx = '.xlsx',
  png = '.png',
  bmp = '.bmp',
  csv = '.csv',
  xml = '.xml',
  html = '.html',
  htm = '.htm',
  tif = '.tif',
  rtf = '.rtf',
  gif = '.gif',
  jpeg = '.jpeg',
  jpg = '.jpg',
  ico = '.ico',
  svg = '.svg',
  tiff = '.tiff',
  webp = '.webp',
  doc = '.doc',
  docx = '.docx',
  txt = '.txt',
  pdf = '.pdf',
}

export const UPLOAD_FILE_FORMATS = {
  excel: joinString([
    EUploadFileFormat.xls,
    EUploadFileFormat.xlsx,
  ], ','),
  image: joinString([
    EUploadFileFormat.png,
    EUploadFileFormat.bmp,
    EUploadFileFormat.gif,
    EUploadFileFormat.jpeg,
    EUploadFileFormat.jpg,
    EUploadFileFormat.ico,
    EUploadFileFormat.svg,
    EUploadFileFormat.tiff,
    EUploadFileFormat.webp,
  ], ','),
  doc: joinString([
    EUploadFileFormat.doc,
    EUploadFileFormat.docx,
  ], ','),
  text: joinString([EUploadFileFormat.txt], ','),
  pdf: joinString([EUploadFileFormat.pdf], ','),
};

// Moscow center coords for map
export const DEFAULT_COORDS = {
  lat: 55.752220,
  lon: 37.615560,
};

export const BYTES_IN_KILOBYTE = 1024;
export const BYTES_IN_MEGABYTE = 1048576;

export const IMPORT_STATES = {
  initial: 'initial',
  fileUploaded: 'fileUploaded',
  fileUploadFailed: 'fileUploadFailed',
  importStarted: 'importStarted',
  importFinished: 'importFinished',
  importFailed: 'importFailed',
  getImportInfoFailed: 'getImportInfoFailed',
  importSentFirstReport: 'importSentFirstReport',
};

export enum EProcessImportStatus {
  failed = 'failed',
  success = 'success',
}

export enum ECommonImportError {
  contractValidationError = 'DomainErrors::ContractValidationError',
  missedRequiredColumnsForImport = 'DomainErrors::MissedRequiredColumnsForImport',
  invalidMimeTypeError = 'DomainErrors::InvalidMimeTypeForImport',
  invalidFileError = 'DomainErrors::InvalidFileForImport',
  invalidColumnIdentifiersImport = 'DomainErrors::InvalidColumnIdentifiersImport',
  persistenceError = 'DomainErrors::PersistenceError',
}

export enum ETendersContextImportError {
  tenderLotsNotFound = 'DomainErrors::TendersContext::TenderLotsNotFound',
  unknownTenderLot = 'DomainErrors::TendersContext::UnknownTenderLot',
  stageHasInvalidStatusToSaveResult = 'DomainErrors::TendersContext::StageHasInvalidStatusToSaveResult',
}

export const IMPORT_ERROR_CODE_TRANSLATE = {
  [ECommonImportError.missedRequiredColumnsForImport]: tt('shared.import.errors.type.missedRequiredColumnsForImport'),
  [ECommonImportError.invalidMimeTypeError]: tt('shared.import.errors.type.invalidMimeType'),
  [ECommonImportError.invalidFileError]: tt('shared.import.errors.type.invalidFile'),
  [ECommonImportError.invalidColumnIdentifiersImport]: tt('shared.import.errors.type.invalidColumnIdentifiersImport'),
  [ECommonImportError.persistenceError]: tt('shared.import.errors.type.persistenceError'),
  [ETendersContextImportError.tenderLotsNotFound]: tt('shared.import.errors.type.tenderLotsNotFound'),
  [ETendersContextImportError.unknownTenderLot]: tt('shared.import.errors.type.unknownTenderLot'),
  [ETendersContextImportError.stageHasInvalidStatusToSaveResult]: tt('shared.import.errors.type.stageHasInvalidStatusToSaveResult'),
};

export enum EOwnershipType {
  own = 'own',
  rent = 'rent',
}

export const BREAKPOINTS = {
  widemobile: 576,
  tablet: 768,
  widelaptop: 1024,
  desktop: 1440,
  mediumscreen: 1880,
  widescreen: 2560,
};

export enum EPaginatorSize {
  small = 'small',
  default = 'default',
}

export enum EInputSize {
  default = 'default',
  large = 'large',
  middle = 'middle',
  small = 'small',
}

export enum EAccountType {
  personal = 'personal',
  corporate = 'corporate',
}

export const DAYS_WITH_LABEL = [
  {
    key: EDayOfWeek.monday,
    label: DayOfWeekLabelShort[EDayOfWeek.monday],
  },
  {
    key: EDayOfWeek.tuesday,
    label: DayOfWeekLabelShort[EDayOfWeek.tuesday],
  },
  {
    key: EDayOfWeek.wednesday,
    label: DayOfWeekLabelShort[EDayOfWeek.wednesday],
  },
  {
    key: EDayOfWeek.thursday,
    label: DayOfWeekLabelShort[EDayOfWeek.thursday],
  },
  {
    key: EDayOfWeek.friday,
    label: DayOfWeekLabelShort[EDayOfWeek.friday],
  },
  {
    key: EDayOfWeek.saturday,
    label: DayOfWeekLabelShort[EDayOfWeek.saturday],
  },
  {
    key: EDayOfWeek.sunday,
    label: DayOfWeekLabelShort[EDayOfWeek.sunday],
  },
];

// https://json-schema.org/understanding-json-schema/index.html
export enum EJsonSchemaType {
  string = 'string',
  integer = 'integer',
  number = 'number',
  boolean = 'boolean',
  array = 'array',
  null = 'null',
}

export enum EJsonSchemaStringFormat {
  date = 'date',
  dateTime = 'date-time',
}

export const enum ESubjectType {
  country = 'country',
  federal = 'federal',
  locality = 'locality',
  region = 'region',
}

export const enum EServiceFeePolicy {
  /** Транзакции оплачивает исполнитель */
  carrierPay = 'carrier_pay',
  /** Транзакции оплачивает заказчик */
  shipperPay = 'shipper_pay',
}

export const SUBJECT_TYPES_TRANSLATION = {
  [ESubjectType.country]: tt('direction.addDirectionDrawer.route.subjectType.country'),
  [ESubjectType.federal]: tt('direction.addDirectionDrawer.route.subjectType.federal'),
  [ESubjectType.region]: tt('direction.addDirectionDrawer.route.subjectType.region'),
  [ESubjectType.locality]: tt('direction.addDirectionDrawer.route.subjectType.locality'),
};

export const subjectTypes = [{
  value: ESubjectType.country,
  label: SUBJECT_TYPES_TRANSLATION[ESubjectType.country],
}, {
  value: ESubjectType.federal,
  label: SUBJECT_TYPES_TRANSLATION[ESubjectType.federal],
}, {
  value: ESubjectType.region,
  label: SUBJECT_TYPES_TRANSLATION[ESubjectType.region],
}, {
  value: ESubjectType.locality,
  label: SUBJECT_TYPES_TRANSLATION[ESubjectType.locality],
}];

export const BOOLEAN_ATTRIBUTE_OPTIONS = [
  {
    id: 'true',
    name: tt('shared.yes'),
  },
  {
    id: 'false',
    name: tt('shared.no'),
  },
];

export const SUBJECT_TYPES_SUFFIX_BY_TRANSLATION = {
  [ESubjectType.country]: tt('direction.addDirectionDrawer.route.subjectTypeSuffixBy.country'),
  [ESubjectType.federal]: tt('direction.addDirectionDrawer.route.subjectTypeSuffixBy.federal'),
  [ESubjectType.region]: tt('direction.addDirectionDrawer.route.subjectTypeSuffixBy.region'),
  [ESubjectType.locality]: tt('direction.addDirectionDrawer.route.subjectTypeSuffixBy.locality'),
};

export enum ESubjectFieldByType {
  country = 'countryIsoCode',
  federal = 'federalDistrictCode',
  region = 'regionIsoCode',
}

export enum EOptionFieldByType {
  isoCode = 'isoCode',
  code = 'code',
}

export enum EConditionOfRequire {
  anyOf = 'any_of',
  allOf = 'all_of',
}

export enum EFileActionMode {
  actionAppend = 'append',
  actionReplace = 'replace',
}

export const DEFAULT_DEBOUNCE_DELAY = 300;

export const MAX_DISTANCE_TO_END_INFINITY_SCROLL = 150;

export const MIN_DISTANCE_TO_END_INFINITY_SCROLL = 100;
