import { ROUTE_TYPE } from '@/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasDigitalQueuesPermission,
  hasExecuteRunsPermission,
  hasMakeFreightRequestsPermission,
  hasShipperWorkplaceSubscription,
  hasTrackRunsPermission,
} from '@/domains/permissions/subscription';
import { canBrowseContractorsByRole } from '@/domains/permissions/role/contractors';
import {
  canBrowseDigitalQueuesByCustomAccessRole,
  canBrowseDigitalQueuesByRole,
} from '@/domains/permissions/role/digitalQueues';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import isCurrentUserContractor from '../domain/isCurrentUserContractor';
import { ROUTE_NAME } from '../domain/constants';

const hasDigitalQueueSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasDigitalQueuesPermission());

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

const hasTrackRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription() || hasShipperWorkplaceSubscription()
    : hasTrackRunsPermission());

const hasExecuteRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription()
    : hasExecuteRunsPermission());

const routes = [
  buildProtectedRoute({
    path: '/contractors',
    name: ROUTE_NAME.contractors,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseContractorsByRole,
        () => hasMakeFreightRequestsSubscription() || hasExecuteRunsSubscription(),
      ],
      component: () => import('@/contexts/contractorsContext/views/ContractorsList/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/contractor_personal_account/sign_in',
    name: ROUTE_NAME.contractorPersonalAccountSignIn,
    meta: {
      type: ROUTE_TYPE.public, // роут доступен как неавторизованным юзерам (контрагент, перешедший по ссылке на страницу ЛК), так и авторизованным (пользователь tms скопировавший ссылку из системы и перешедший по ней в ЛК контрагента))
      component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountSignIn/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/contractor_personal_account/unsubscribe',
    name: ROUTE_NAME.contractorPersonalAccountUnsubscribe,
    meta: {
      type: ROUTE_TYPE.public,
      component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountUnsubscribing/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/contractor_personal_account/runs/:id',
    name: ROUTE_NAME.contractorPersonalAccountRun,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [() => isCurrentUserContractor() || hasTrackRunsSubscription()],
      component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/components/RunView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/contractor_personal_account/runs',
    name: ROUTE_NAME.contractorPersonalAccountRuns,
    redirect: '/contractor_personal_account/runs/current',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [() => isCurrentUserContractor() || hasTrackRunsSubscription()],
      component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/index.vue'),
    },
    children: [
      {
        path: 'current',
        component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/components/RunsCurrent/index.vue'),
      },
      {
        path: 'planned',
        component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/components/RunsPlanned/index.vue'),
      },
      {
        path: 'finished',
        component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountRuns/components/RunsFinished/index.vue'),
      },
    ],
  }),
  buildProtectedRoute({
    path: '/contractor_personal_account/incidents',
    name: ROUTE_NAME.contractorPersonalAccountIncidents,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [() => isCurrentUserContractor() || hasTrackRunsSubscription()],
      component: () => import('@/contexts/contractorsContext/views/ContractorPersonalAccountIncidents/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/digital_queues/:id?',
    name: ROUTE_NAME.digitalQueue,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [() => hasDigitalQueueSubscription()
         && (canBrowseDigitalQueuesByRole() || canBrowseDigitalQueuesByCustomAccessRole()),
      ],
      component: () => import('@/contexts/contractorsContext/views/DigitalQueueInfoView/index.vue'),
    },
  }),
];

export default routes;
