<template>
  <button
    type="button"
    class="sber-button"
    :class="[
      `sber-button__${size}`,
      { 'sber-button-collapsed': isCollapsed }
    ]"
    @click.prevent="handleClick"
  >
    <component
      :is="icon"
    />
    <span
      v-if="!isCollapsed"
      class="sber-button__label"
    >
      {{ tt('infrastructure.action.signInSberBusiness') }}
    </span>
  </button>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
} from 'vue';
import { useRoute } from 'vue-router';

import tt from '@/i18n/utils/translateText';
import { TReachedResponse } from '@/api/types';
import SberSvg from '@/assets/svg/logo/sber.svg';
import useRouteState from '@/composables/useRouteState';
import { modifySessionFields } from '@/domains/convertSessionResponse';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { useProcessingSberParams } from './composables/useProcessingSberParams';
import {
  TSberBusinessPayloadParams,
  TSberBusinessRedirectParams,
  TSberBusinessResponse,
} from './domain/types';
import { ESberButtonSize, EIntegrationEvent } from './domain/constants';
import {
  authorizeSberBusinessAccount,
  getRedirectParamsToSberBusiness,
  linkingSberBusinessAccount,
} from './services';

export default defineComponent({
  name: 'SberBusinessIntegrationButton',
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    integrationEvent: {
      type: String as PropType<EIntegrationEvent>,
      required: true,
    },
    size: {
      type: String as PropType<ESberButtonSize>,
      default: ESberButtonSize.small,
    },
  },
  emits: ['completeAuth', 'completeLinking', 'completeRegistration'],
  setup(props, { emit }) {
    const route = useRoute();
    const { setRouteState } = useRouteState();
    const currentRouteKey = computed(() => route.path);
    const isCamelizeParamAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.camelizeParams));

    const {
      generateRedirectUrl,
      compareHashParams,
      saveUserDataParams,
      removeHashParams,
    } = useProcessingSberParams(currentRouteKey);

    const handleClick = () => {
      getRedirectParamsToSberBusiness()
        .then((result: TReachedResponse<TSberBusinessRedirectParams>) => {
          if (!result) return;
          const url = generateRedirectUrl(result.data);
          window.open(url, '_blank');
        });
    };

    const handleProceedSberBusinessIntegration = (code: string, nonce: string) => {
      const payload: TSberBusinessPayloadParams = {
        code,
        nonce,
        redirectUrl: `${import.meta.env.VITE_APP_HOST}${currentRouteKey.value}`,
      };
      switch (props.integrationEvent) {
        case EIntegrationEvent.linking:
          linkingSberBusinessAccount(payload)
            .then(() => {
              emit('completeLinking');
            })
            .finally(() => {
              setRouteState({});
            });
          break;
        case EIntegrationEvent.authorization:
          authorizeSberBusinessAccount(payload)
            .then((response: TReachedResponse<TSberBusinessResponse>) => {
              if (!response) return null;
              if (response.data.session) {
                setRouteState({});
                emit('completeAuth', modifySessionFields(response.data.session));
              }
              if (response.data.userData) {
                const key = isCamelizeParamAvailable.value ? 'socialAuth' : 'social_auth';
                saveUserDataParams(response.data.userData);
                setRouteState({ [key]: 'sber' });
                emit('completeRegistration', response.data.userData);
              }
            })
            .catch(() => {
              setRouteState({});
            });
          break;
        default: // Do nothing
      }
    };

    const processSberParams = () => {
      const code = route.query.code as string;
      const state = route.query.state as string;
      const nonce = route.query.nonce as string;
      if (code && state && nonce) {
        if (compareHashParams({
          state,
          nonce,
        })) {
          handleProceedSberBusinessIntegration(code, nonce);
          removeHashParams();
        }
      }
    };

    onBeforeMount(() => {
      processSberParams();
    });

    return {
      icon: SberSvg,
      handleClick,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
