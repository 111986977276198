import { computed, watch } from 'vue';

import { rebuildRejectedProtectedRoutes } from '@/router/utils/processRejectedRoute';
import { EVatRate } from '@/domains/getVatRateOptions';
import useStore from '@/store/useStore';
import { ETenantPriceViewMode } from '@/contexts/accountingContext/domain/constants';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';

const useTenantServices = () => {
  const store = useStore();
  const priceViewMode = computed(() => store.state.tenants.currentTenantInfo?.legalEntity?.priceViewMode);

  const fetchCommonTenantData = () => Promise.all([
    store.dispatch('tenants/fetchCurrentTenantInfo'),
    store.dispatch('tenants/fetchReferenceData'),
    store.dispatch('notifications/fetchUnreadNotificationsCount'),
  ]);

  const vatRateByTenantSettings = computed(
    () => (priceViewMode.value !== ETenantPriceViewMode.without_vat ? EVatRate.vat_20 : EVatRate.vat_none));

  // Отрабатывает при смене тенанта и при первичном сете тенанта после авторизации
  watch(() => store.state.tenants.currentTenant?.id, (newCurrentTenantId, prevCurrentTenantId) => {
    if (!newCurrentTenantId) return;
    fetchCommonTenantData().then(() => {
      if (!prevCurrentTenantId) {
        // при первичном сете тенанта после авторизации делаем редирект
      // к последнему сохраненному роуту и сбрасываем его значение
        redirectToInitialRoute(store.state.app.pathBeforeAuth);
        store.commit('app/SET_LAST_PATH_BEFORE_AUTH', null);
      } else {
        // при смене тенанта редиректим по требованиям по умолчанию
        redirectToInitialRoute();
      }
    });
    rebuildRejectedProtectedRoutes();
  });

  return {
    vatRateByTenantSettings,

    fetchCommonTenantData,
  };
};

export default useTenantServices;
