import {
  Ref,
  computed,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  getMonthsFromDate,
  getNowISO,
  getYearsFromDate,
} from '@/utils/dateUtils';
import { EMonth } from '@/ui/types/constants';

export const useInteractWithDatePicker = (minDate: Ref<string>, maxDate: Ref<string>) => {
  const currentDate = getNowISO();

  const monthSelectValue = ref(getMonthsFromDate(currentDate) + 1);
  const yearSelectValue = ref(getYearsFromDate(currentDate));

  const yearsOptions = computed(() => {
    const maxYear = getYearsFromDate(maxDate.value);
    const minYear = getYearsFromDate(minDate.value);

    return Array.from({ length: maxYear - minYear + 1 }).map((_, index: number) => ({
      value: minYear + index,
      label: minYear + index,
    }));
  });

  const monthsOptions = computed(
    () => Object.keys(EMonth).filter((value: string) => Number.isNaN(Number(value))).map(
      (month: string, index: number) => ({
        value: index + 1,
        label: tt(`shared.months.${month as keyof typeof EMonth}`),
      })),
  );

  const handleSetCurrentDate = () => {};

  return {
    yearsOptions,
    monthsOptions,
    monthSelectValue,
    yearSelectValue,

    handleSetCurrentDate,
  };
};
