import {
  computed,
  Ref,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  EBillingPlanCode,
  EBillingPlanCodeStatus,
  EBillingPlanGroup,
} from '@/contexts/billingContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import useStore from '@/store/useStore';
import { TSubscription } from '@/types';
import { getIsDisabledTab } from '@/contexts/billingContext/components/BillingPlanSelectionModal/components/BillingPlanSelectionModalContent/components/BillingPlanSelection/domain/getIsDisabledTab';

import { TGroupedAvailablePlans } from '../domain/types';

export const useView = (groupedAvailablePlans?: Ref<TGroupedAvailablePlans | null>) => {
  const store = useStore();
  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const currentActiveSubscription = computed(
    () => currentTenantInfo.value?.subscriptions?.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null,
  );

  const tabWithCurrentSubscription = computed(() => {
    if (!currentActiveSubscription.value) return EBillingPlanGroup.carrier;

    if (currentActiveSubscription?.value?.planCode?.includes(EBillingPlanGroup.carrier)) {
      return EBillingPlanGroup.carrier;
    }
    if (currentActiveSubscription?.value?.planCode?.includes(EBillingPlanGroup.freightForwarder)) {
      return EBillingPlanGroup.freightForwarder;
    }
    if (currentActiveSubscription?.value?.planCode?.includes(EBillingPlanCode.shipper)) {
      return EBillingPlanGroup.shipper;
    }
    return EBillingPlanGroup.carrier;
  });

  const billingPlanGroupType = ref<EBillingPlanGroup>(tabWithCurrentSubscription.value);

  const textForMoreAboutPlan = computed(() => {
    if (billingPlanGroupType.value === EBillingPlanGroup.cargoOwner) {
      return '';
    }
    if (billingPlanGroupType.value === EBillingPlanGroup.shipper) {
      return tt('billing.moreAboutPlan');
    }
    return tt('billing.modal.detailedComparisonAndDetailingOfPlans');
  });

  const planSelectionCards = computed(() => {
    switch (billingPlanGroupType.value) {
      case EBillingPlanGroup.carrier:
        return 'BillingCardsForCarrier';
      case EBillingPlanGroup.freightForwarder:
        return 'BillingCardsForFreightForwarder';
      case EBillingPlanGroup.shipper:
        return 'BillingCardsForShipper';
      case EBillingPlanGroup.cargoOwner:
        return 'BillingCardsForCargoOwner';
      default:
        return 'BillingCardsForCarrier';
    }
  });

  const availablePlansForGroup = computed(() => {
    switch (billingPlanGroupType.value) {
      case EBillingPlanGroup.carrier:
        return groupedAvailablePlans?.value?.[EBillingPlanGroup.carrier];
      case EBillingPlanGroup.freightForwarder:
        return groupedAvailablePlans?.value?.[EBillingPlanGroup.freightForwarder];
      case EBillingPlanGroup.shipper:
        return groupedAvailablePlans?.value?.[EBillingPlanGroup.shipper];
      case EBillingPlanGroup.cargoOwner:
        return groupedAvailablePlans?.value?.[EBillingPlanGroup.cargoOwner];
      default:
        return groupedAvailablePlans?.value?.[EBillingPlanGroup.carrier];
    }
  });

  const isBillingCarrierStartPlanAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingCarrierStartPlan),
  );

  // Отображаем сноску если открыта вкладка Перевозчика и (активный план carrierStart или carrierStart есть в доступных подписках)
  const isFootnoteShown = computed(
    () => isBillingCarrierStartPlanAvailable.value
      && planSelectionCards.value === 'BillingCardsForCarrier'
      && (currentActiveSubscription?.value?.planCode === EBillingPlanCode.carrierStart
        || groupedAvailablePlans?.value?.carrier.includes({
          planCode: EBillingPlanCode.carrierStart,
          price: 0,
        })
      ),
  );

  const isCarrierTabDisabled = computed(() => getIsDisabledTab({
    planCodeGroup: EBillingPlanGroup.carrier,
    groupedAvailablePlans: groupedAvailablePlans?.value,
    currentActiveSubscription: currentActiveSubscription.value,
  }));

  const isCargoOwnerTabDisabled = computed(() => getIsDisabledTab({
    planCodeGroup: EBillingPlanGroup.cargoOwner,
    groupedAvailablePlans: groupedAvailablePlans?.value,
    currentActiveSubscription: currentActiveSubscription.value,
  }));

  const isShipperTabDisabled = computed(() => getIsDisabledTab({
    planCodeGroup: EBillingPlanGroup.shipper,
    groupedAvailablePlans: groupedAvailablePlans?.value,
    currentActiveSubscription: currentActiveSubscription.value,
  }));

  const isFreightForwarderTabDisabled = computed(() => getIsDisabledTab({
    planCodeGroup: EBillingPlanGroup.freightForwarder,
    groupedAvailablePlans: groupedAvailablePlans?.value,
    currentActiveSubscription: currentActiveSubscription.value,
  }));

  const hasSubscriptions = computed(() => currentTenantInfo.value?.subscriptions?.length !== 0);

  const hasPastSubscriptions = computed(() => currentTenantInfo.value?.hasPastSubscriptions || false);

  const hasInfoAboutTrialPeriod = computed(() => !hasSubscriptions.value && !hasPastSubscriptions.value);

  return {
    textForMoreAboutPlan,
    planSelectionCards,
    billingPlanGroupType,
    isFootnoteShown,
    hasInfoAboutTrialPeriod,
    currentActiveSubscription,
    isCarrierTabDisabled,
    isCargoOwnerTabDisabled,
    isShipperTabDisabled,
    isFreightForwarderTabDisabled,
    availablePlansForGroup,
  };
};
