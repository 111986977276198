import { EStatusType } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';

export enum EExecutionRequestAgreementRole {
  asCustomer = 'as_customer',
  asExecutor = 'as_executor',
}

export enum EExecutionRequestSide {
  executor = 'executor',
  customer = 'customer',
}

export enum ERunAction {
  cargoLoading = 'cargo_loading',
  cargoUnloading = 'cargo_unloading',
}

export enum ELoadingType {
  rear = 'rear',
  side = 'side',
  sideOrRear = 'side_or_rear',
  top = 'top',
  fullAwning = 'full_awning',
}

export enum ESharedActionTranslate {
  loadingTypes = 'loadingTypes',
  unloadingTypes = 'unloadingTypes',
}

export const LOADING_TYPES_BY_ACTION = {
  [ERunAction.cargoLoading]: [
    {
      label: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.side`),
      value: ELoadingType.side,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.rear`),
      value: ELoadingType.rear,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.fullAwning`),
      value: ELoadingType.fullAwning,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.sideOrRear`),
      value: ELoadingType.sideOrRear,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.top`),
      value: ELoadingType.top,
    },
  ],
  [ERunAction.cargoUnloading]: [
    {
      label: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.side`),
      value: ELoadingType.side,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.rear`),
      value: ELoadingType.rear,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.fullAwning`),
      value: ELoadingType.fullAwning,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.sideOrRear`),
      value: ELoadingType.sideOrRear,
    },
    {
      label: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.top`),
      value: ELoadingType.top,
    },
  ],
};

export const LOADING_TYPES_TRANSLATE_BY_ACTION = {
  [ERunAction.cargoLoading]: {
    [ELoadingType.side]: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.side`),
    [ELoadingType.rear]: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.rear`),
    [ELoadingType.fullAwning]: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.fullAwning`),
    [ELoadingType.sideOrRear]: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.sideOrRear`),
    [ELoadingType.top]: tt(`order.shared.${ESharedActionTranslate.loadingTypes}.top`),
  },
  [ERunAction.cargoUnloading]: {
    [ELoadingType.side]: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.side`),
    [ELoadingType.rear]: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.rear`),
    [ELoadingType.fullAwning]: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.fullAwning`),
    [ELoadingType.sideOrRear]: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.sideOrRear`),
    [ELoadingType.top]: tt(`order.shared.${ESharedActionTranslate.unloadingTypes}.top`),
  },
};

export const RUN_ACTION_TRANSLATION = {
  cargo_loading: tt('shared.loading'),
  cargo_unloading: tt('shared.unloading'),
};

export const TRANSIT_POINT_ACTION_TRANSLATION = {
  cargo_loading: tt('operationalDocuments.documentConstructor.transitPoints.shipper'),
  cargo_unloading: tt('operationalDocuments.documentConstructor.transitPoints.consignee'),
};

export const VOLUME_UNIT = {
  run: tt('shared.runs.plural'),
  ton: tt('shared.units.tons.declension2'),
  cubic_meter: tt('shared.units.cubicMeters.shortest'),
};

export enum EExecutionRequestsStatus {
  active = 'active',
  canceledByExecutor = 'canceled_by_executor',
  canceledByCustomer = 'canceled_by_customer',
  completed = 'completed',
}

export const EXECUTION_REQUEST_STATUS = {
  [EExecutionRequestsStatus.active]: {
    text: tt('freightExecution.shared.executionRequestStatuses.active'),
    type: EStatusType.processing,
  },
  [EExecutionRequestsStatus.canceledByExecutor]: {
    text: tt('freightExecution.shared.executionRequestStatuses.canceledByExecutor'),
    type: EStatusType.error,
  },
  [EExecutionRequestsStatus.canceledByCustomer]: {
    text: tt('freightExecution.shared.executionRequestStatuses.canceledByCustomer'),
    type: EStatusType.error,
  },
  [EExecutionRequestsStatus.completed]: {
    text: tt('freightExecution.shared.executionRequestStatuses.completed'),
    type: EStatusType.success,
  },
};

export enum EExecutionRequestsState {
  canceledByExecutor = 'canceled_by_executor',
  canceledByCustomer = 'canceled_by_customer',
  completed = 'completed',
  resourceAllocation = 'resource_allocation',
  inProgress = 'in_progress',
  runsCompleted = 'runs_completed',
}

export const EXECUTOR_EXECUTION_REQUEST_STATE = {
  [EExecutionRequestsState.canceledByExecutor]: {
    text: tt('freightExecution.shared.executionRequestStatuses.canceledByExecutor'),
    type: EStatusType.error,
  },
  [EExecutionRequestsState.canceledByCustomer]: {
    text: tt('freightExecution.shared.customerExecutionRequestStates.canceledByCustomer'),
    type: EStatusType.error,
  },
  [EExecutionRequestsState.completed]: {
    text: tt('freightExecution.shared.executionRequestStatuses.completed'),
    type: EStatusType.success,
  },
  [EExecutionRequestsState.resourceAllocation]: {
    text: tt('freightExecution.shared.customerExecutionRequestStates.resourceAllocation'),
    type: EStatusType.processing,
  },
  [EExecutionRequestsState.inProgress]: {
    text: tt('freightExecution.shared.executorExecutionRequestStates.inProgress'),
    type: EStatusType.processing,
  },
  [EExecutionRequestsState.runsCompleted]: {
    text: tt('freightExecution.shared.customerExecutionRequestStates.runsCompleted'),
    type: EStatusType.processing,
  },
};

export const EXECUTION_REQUEST_TABLE_FILTER_SELECT_TYPE = 'lookupMethodType';
export const EXECUTION_REQUEST_TABLE_FILTER_SELECT_QUANTITY = 'withUnassignedQuantity';

export enum EExecutionRequestsFilter {
  all = 'all',
  active = 'active',
  canceledByExecutor = 'canceled_by_executor',
  canceledByCustomer = 'canceled_by_customer',
  completed = 'completed',
}
