import { normalizePayloadToDataIds } from '@/utils';

import * as types from './mutation-types';
import { TNotificationsMutations } from './types';

const mutations: TNotificationsMutations = {
  [types.RESET_STATE](state) {
    state.unreadCount = 0;
    state.isNotificationsListVisible = false;
    state.isTabWithUnreadNotifications = false;
  },
  [types.SET_UNREAD_COUNT](state, unreadCount) {
    state.unreadCount = unreadCount;
  },
  [types.INCREMENT_UNREAD_COUNT](state) {
    state.unreadCount += 1;
  },
  [types.DECREMENT_UNREAD_COUNT](state) {
    if (state.unreadCount === 0) return;
    state.unreadCount -= 1;
  },
  [types.TOGGLE_NOTIFICATION_LIST_VISIBLE](state, visible) {
    state.isNotificationsListVisible = visible;
  },
  [types.SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS](state, isTabWithUnreadNotifications) {
    state.isTabWithUnreadNotifications = isTabWithUnreadNotifications;
  },
  [types.SET_NOTIFICATIONS](state, notifications) {
    const { ids, data } = normalizePayloadToDataIds(notifications);
    state.notifications = {
      ids,
      data,
    };
  },
  [types.APPEND_NOTIFICATIONS](state, notifications) {
    if (Array.isArray(state.notifications.ids)) {
      const { ids, data } = normalizePayloadToDataIds(notifications);
      state.notifications.ids = state.notifications.ids.concat(ids);
      state.notifications.data = {
        ...state.notifications.data,
        ...data,
      };
    }
  },
  [types.SET_IS_NOTIFICATIONS_LOADING](state, isNotificationsLoading) {
    state.isNotificationsLoading = isNotificationsLoading;
  },
  [types.CLEAR_NOTIFICATIONS](state) {
    state.notifications = {
      data: null,
      ids: null,
    };
  },
  [types.CHANGE_NOTIFICATION_READ_STATE](state, { notificationId, isRead }) {
    const notification = state.notifications.data?.[notificationId];
    if (notification) {
      notification.isRead = isRead;
    }
  },
};

export default mutations;
