<template>
  <Form
    class="billing-shipper-form"
    :model="formData"
    :layout="ELayout.vertical"
    autocomplete="off"
  >
    <div class="billing-shipper-form__row">
      <FormItem
        name="subscriptionAt"
        v-bind="validateInfos.subscriptionAt"
        :label="tt('shared.activationDate')"
      >
        <DatePicker
          v-model:value="formData.subscriptionAt"
          class="billing-shipper-form__data-picker"
          :format="DATE_FORMAT.fullDate"
          :valueFormat="DATE_FORMAT.fullDate"
          :disabled="!isBillingValidFrom1MarchAvailable"
          :disabledDate="(date) => getIsDisabledRangeForDate(date, 60)"
          @change="handleFieldChange('subscriptionAt')"
        />
      </FormItem>
    </div>

    <div class="billing-shipper-form__row">
      <FormItem
        name="organizationPhone"
        v-bind="validateInfos.organizationPhone"
        :label="tt('shared.tenantPhone')"
      >
        <PhoneInput
          v-model:value="formData.organizationPhone"
          hasMask
          @input="handleFieldChange('organizationPhone')"
          @blur="handleFieldValidate('organizationPhone')"
        />
      </FormItem>
    </div>

    <FormItem
      name="invoicingEmail"
      v-bind="validateInfos.invoicingEmail"
      class="billing-shipper-form__form-item"
      :label="tt('shared.invoicingEmail')"
    >
      <EmailConfirmation
        :email="formData.invoicingEmail || ''"
        :isConfirmationRequired="!isEmailConfirmed"
        :requestCodeFunction="requestConfirmationCode"
        :confirmCodeFunction="confirmCodeForEmail"
        @confirmed="handleEmailConfirmed"
      >
        <Input
          v-model:value="formData.invoicingEmail"
          :disabled="isEmailConfirmed"
          @input="handleFieldChange('invoicingEmail')"
          @blur="handleFieldValidate('invoicingEmail')"
        />

        <CheckColoredSvg v-if="isEmailConfirmed" />
      </EmailConfirmation>
    </FormItem>

    <FormItem
      name="publicOfferAccept"
      class="billing-shipper-form__form-item"
    >
      <Checkbox
        v-model:checked="formData.publicOfferAccept"
      >
        {{ `${tt('shared.readAndAccept')} ${ tt('shared.licensed')}` }}

        <SmartLink :href="publicOfferLink" alwaysOpenNewTab>
          {{ tt('shared.offerContract') }}
        </SmartLink>
      </Checkbox>
    </FormItem>
  </Form>
</template>

<script lang="ts">

import {
  PropType,
  defineComponent,
  toRef,
} from 'vue';
import {
  Form,
  FormItem,
  DatePicker,
} from 'ant-design-vue';

import CheckColoredSvg from '@/assets/svg/16x16/check-colored.svg';
import tt from '@/i18n/utils/translateText';
import {
  Checkbox,
  Input,
  PhoneInput,
} from '@/ui';
import { ESize } from '@/ui/types';
import {
  ELayout,
  DATE_FORMAT,
} from '@/constants';
import SmartLink from '@/components/SmartLink/index.vue';
import EmailConfirmation from '@/components/EmailConfirmation/index.vue';
import { confirmCodeForEmail, requestConfirmationCode } from '@/contexts/billingContext/services';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { getIsDisabledRangeForDate } from '@/utils/dateUtils';

import { useInteractWithForm } from './application/useInteractWithForm';
import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingShipperForm',
  components: {
    SmartLink,
    Form,
    FormItem,
    Input,
    PhoneInput,
    DatePicker,
    Checkbox,
    EmailConfirmation,
    CheckColoredSvg,
  },
  props: {
    planCode: {
      type: [String, null] as PropType<EBillingPlanCode | null>,
      required: true,
    },
  },
  setup(props, { expose }) {
    const planCode = toRef(props, 'planCode');

    const {
      publicOfferLink,
      isBillingValidFrom1MarchAvailable,
    } = useView(planCode);

    const {
      formData,
      validateInfos,
      isSubmitDisabled,
      isEmailConfirmed,

      handleFieldValidate,
      handleFieldChange,
      handleEmailConfirmed,
      onSubmit,
      setResponseErrors,
    } = useInteractWithForm();

    expose({
      isSubmitDisabled,

      onSubmit,
      setResponseErrors,
    });

    return {
      formData,
      validateInfos,
      isSubmitDisabled,
      isEmailConfirmed,
      ELayout,
      DATE_FORMAT,
      ESize,
      publicOfferLink,
      isBillingValidFrom1MarchAvailable,

      tt,
      handleFieldValidate,
      handleFieldChange,
      handleEmailConfirmed,
      requestConfirmationCode,
      confirmCodeForEmail,
      onSubmit,
      setResponseErrors,
      getIsDisabledRangeForDate,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
