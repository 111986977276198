import * as types from './mutation-types';
import { TProvidersMutations } from './types';

const mutations: TProvidersMutations = {
  [types.RESET_STATE](state) {
    state.providers = null;
  },
  [types.SET_PROVIDERS](state, providers) {
    state.providers = providers;
  },
};

export default mutations;
