<template>
  <div class="billing-plan-selection">
    <Loadable
      :isLoading="isLoading"
      :isDone="isDone"
    >
      <RadioGroup
        :optionType="ERadioButtonOptionType.button"
        :value="billingPlanGroupType"
        @update:value="billingPlanGroupType = $event"
      >
        <RadioButton
          :value="EBillingPlanGroup.carrier"
          :disabled="isCarrierTabDisabled"
        >
          {{ tt('shared.carrier') }}
        </RadioButton>
        <RadioButton
          :value="EBillingPlanGroup.freightForwarder"
          :disabled="isFreightForwarderTabDisabled"
        >
          {{ tt('shared.forwarder') }}
        </RadioButton>
        <RadioButton
          :value="EBillingPlanGroup.cargoOwner"
          :disabled="isCargoOwnerTabDisabled"
        >
          {{ tt('shared.cargoOwner') }}
        </RadioButton>
        <RadioButton
          :value="EBillingPlanGroup.shipper"
          :disabled="isShipperTabDisabled"
        >
          {{ tt('shared.shipper') }}
        </RadioButton>
      </RadioGroup>
      <div class="billing-plan-selection__cards">
        <component
          :is="planSelectionCards"
          :availablePlans="availablePlansForGroup"
          @change="$emit('change', $event)"
        />
      </div>
      <div
        class="billing-plan-selection__footer-info-block"
      >
        <SmartLink
          v-if="textForMoreAboutPlan"
          :href="MORE_INFO_ABOUT_PLANS_URL"
          alwaysOpenNewTab
        >
          {{ textForMoreAboutPlan }}
        </SmartLink>
        <p
          v-if="isFootnoteShown"
          class="billing-plan-selection__footer-info-block_footnote"
        >
          * {{ tt('billing.modal.providedAsPartOfFunctionalityTestingInInterestsOfLicensor') }}
        </p>
      </div>
    </Loadable>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
} from 'vue';

import { RadioButton, RadioGroup } from '@/ui';
import Loadable from '@/components/Loadable/index.vue';
import tt from '@/i18n/utils/translateText';
import { ERadioButtonOptionType } from '@/ui/types';
import SmartLink from '@/components/SmartLink/index.vue';
import { MORE_INFO_ABOUT_PLANS_URL } from '@/domains/constants';
import { EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';

import BillingCardsForCarrier from './components/BillingCardsForCarrier/index.vue';
import BillingCardsForFreightForwarder from './components/BillingCardsForFreightForwarder/index.vue';
import BillingCardsForShipper from './components/BillingCardsForShipper/index.vue';
import BillingCardsForCargoOwner from './components/BillingCardsForCargoOwner/index.vue';
import { useView } from './application/useView';
import { useFetchAvailablePlans } from './application/useFetchAvailablePlans';

export default defineComponent({
  name: 'BillingPlanSelection',
  components: {
    RadioGroup,
    RadioButton,
    SmartLink,
    BillingCardsForCarrier,
    BillingCardsForFreightForwarder,
    BillingCardsForShipper,
    BillingCardsForCargoOwner,
    Loadable,
  },
  emits: ['change'],
  setup() {
    const {
      groupedAvailablePlans,
      isLoading,
      isDone,

      fetchAvailablePlans,
    } = useFetchAvailablePlans();

    const {
      textForMoreAboutPlan,
      planSelectionCards,
      billingPlanGroupType,
      isFootnoteShown,
      isCarrierTabDisabled,
      isCargoOwnerTabDisabled,
      isShipperTabDisabled,
      isFreightForwarderTabDisabled,
      availablePlansForGroup,
    } = useView(groupedAvailablePlans);

    onMounted(() => {
      fetchAvailablePlans();
    });

    return {
      ERadioButtonOptionType,
      planSelectionCards,
      MORE_INFO_ABOUT_PLANS_URL,
      EBillingPlanGroup,
      textForMoreAboutPlan,
      billingPlanGroupType,
      isFootnoteShown,
      groupedAvailablePlans,
      availablePlansForGroup,
      isCarrierTabDisabled,
      isCargoOwnerTabDisabled,
      isShipperTabDisabled,
      isFreightForwarderTabDisabled,
      isLoading,
      isDone,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
