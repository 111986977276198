<template>
  <div
    v-for="card in infoForCarrierPlanCards"
    :key="card.planCode"
  >
    <BillingPlanSelectionCard
      :title="card.title"
      :subtitle="card.subtitle"
      :planCode="card.planCode"
      :price="card.price"
      :forExtended="card.isExpanding"
      :hasInfoAboutTrialPeriod="card.hasInfoAboutTrialPeriod"
      :isActivePlan="card.isActivePlan"
      @change="$emit('change', $event)"
    >
      <template #description>
        <li v-for="(description, index) in card.description" :key="index">
          {{ description }}
        </li>
      </template>
    </BillingPlanSelectionCard>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EExperimentalFeatures } from '@/domains/constants';
import { EBillingPlanCode, EBillingPlaneCodePrice } from '@/contexts/billingContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { TBillingAvailablePlansResponse, TInfoForPlanCards } from '@/contexts/billingContext/domain/types';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';
import { useView } from '../../application/useView';

export default defineComponent({
  name: 'BillingCardsForCarrier',
  components: { BillingPlanSelectionCard },
  props: {
    availablePlans: {
      type: Array as PropType<TBillingAvailablePlansResponse>,
      default: () => [],
    },
  },
  emits: ['change'],
  setup(props) {
    const isBillingCarrierStartPlanAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.billingCarrierStartPlan),
    );

    const { hasInfoAboutTrialPeriod, currentActiveSubscription } = useView();

    const infoForCarrierPlanCards = ref<TInfoForPlanCards[]>([
      {
        title: tt('billing.planCode.start'),
        subtitle: tt('billing.modal.forParticipateTenders'),
        price: EBillingPlaneCodePrice.carrierStart,
        planCode: EBillingPlanCode.carrierStart,
        isExpanding: false,
        hasInfoAboutTrialPeriod: false,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierStart,
        description: [
          tt('billing.modal.twoManagersForOrganization'),
          tt('billing.modal.accessToTenders'),
        ],
      },
      {
        title: tt('billing.planCode.basic'),
        subtitle: tt('billing.modal.forSmallAndMediumOrganizations'),
        price: EBillingPlaneCodePrice.carrierBasic,
        planCode: EBillingPlanCode.carrierBasic,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isExpanding: false,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierBasic,
        description: [
          tt('billing.modal.twoManagersForOrganization'),
          tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndEightTools'),
        ],
      },
      {
        title: tt('billing.planCode.extended'),
        subtitle: tt('billing.modal.forLargeCarriers'),
        price: EBillingPlaneCodePrice.carrierExtended,
        planCode: EBillingPlanCode.carrierExtended,
        hasInfoAboutTrialPeriod: hasInfoAboutTrialPeriod.value,
        isActivePlan: currentActiveSubscription.value?.planCode === EBillingPlanCode.carrierExtended,
        isExpanding: true,
        description: [
          tt('billing.modal.unlimitedNumberOfManagersInTheOrganization'),
          tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate'),
          tt('billing.modal.transportationPriceCalculator'),
        ],
      },
    ].filter(
      (card: TInfoForPlanCards) => (props.availablePlans?.some((plan) => plan.planCode === card.planCode)
      || card.isActivePlan) && ((
        isBillingCarrierStartPlanAvailable.value && card.planCode === EBillingPlanCode.carrierStart
      ) || card.planCode !== EBillingPlanCode.carrierStart)));

    return {
      infoForCarrierPlanCards,
      EBillingPlaneCodePrice,
      EBillingPlanCode,
      currentActiveSubscription,

      tt,
    };
  },
});
</script>
