import { getProviders } from '@/contexts/executorsLookupContext/services';
import { TReachedResponse } from '@/api/types';
import { TProvider } from '@/contexts/executorsLookupContext/domain/types';

import * as types from './mutation-types';
import { TProvidersActions } from './types';

const actions: TProvidersActions = {
  fetchProviders: ({ commit }) => getProviders()
    .then((response: TReachedResponse<TProvider[]>) => {
      commit(types.SET_PROVIDERS, response?.data || []);
    }),
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
};

export default actions;
