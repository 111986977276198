import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierStarterWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasExecuteRunsPermission,
  hasMakeFreightRequestsPermission,
} from '@/domains/permissions/subscription';
import { canBrowsePartnershipsByRole } from '@/domains/permissions/role/partnerships';
import isTenantVerified from '@/domains/isTenantVerified';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import {
  EPartnershipRouteName,
  EPartnershipsRouteName,
} from '../domain/constants';

const hasExecuteRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription()
    : hasExecuteRunsPermission());

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

const routes = [
  buildProtectedRoute({
    path: '/partnerships',
    name: EPartnershipsRouteName.partnerships,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowsePartnershipsByRole,
        isTenantVerified,
        () => hasMakeFreightRequestsSubscription() || hasExecuteRunsSubscription(),
      ],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/index.vue'),
    },
    redirect: '/partnerships/partners',
    children: [
      {
        path: 'partners',
        name: EPartnershipsRouteName.partnershipsPartners,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsPartners/index.vue'),
      },
      {
        path: 'invitations',
        name: EPartnershipsRouteName.partnershipsInvitations,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsInvitations/index.vue'),
      },
      {
        path: 'contracts',
        name: EPartnershipsRouteName.partnershipsContracts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsContracts/index.vue'),
      },
    ],
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id',
    name: EPartnershipRouteName.partnershipInfo,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/index.vue'),
    },
    redirect: (to) => ({
      name: EPartnershipRouteName.partnershipsPartnerResources,
      params: { id: to.params.id },
    }),
    children: [
      {
        path: 'resources',
        name: EPartnershipRouteName.partnershipsPartnerResources,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerResources/index.vue'),
      },
      {
        path: 'contracts',
        name: EPartnershipRouteName.partnershipsPartnerContracts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerContracts/index.vue'),
      },
      {
        path: 'contacts',
        name: EPartnershipRouteName.partnershipsPartnerContacts,
        component: () => import('@/contexts/partnershipsContext/views/PartnershipInfoView/components/PartnershipsPartnerContacts/index.vue'),
      },
    ],
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id/vehicles/:vehicleId',
    name: 'partnershipVehicle',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipVehicle/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/partnerships/partners/:id/drivers/:driverId',
    name: 'partnershipDriver',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowsePartnershipsByRole],
      component: () => import('@/contexts/partnershipsContext/views/PartnershipDriver/index.vue'),
    },
  }),
];

export default routes;
