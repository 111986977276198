import { computed, Ref } from 'vue';

import tt from '@/i18n/utils/translateText';
import {
  getDayOfCurrentWeek,
  getDaysCountInCurrentMonth,
  getIsDateBetweenOrSame,
  isToday,
} from '@/utils/dateUtils';
import { TDataPickerDayState, EMonth } from '@/ui/types';

type TUseView = {
  month: Ref<EMonth>,
  year: Ref<number>,
  selectedDate: Ref<string | null>,
  minDate: Ref<string>,
  maxDate: Ref<string>,
};

export const useView = ({
  month,
  year,
  selectedDate,
  minDate,
  maxDate,
}: TUseView) => {
  const startDate = computed(() => `${year.value}-${month.value}-01`);

  const days = computed(() => {
    const dayOfCurrentWeek = getDayOfCurrentWeek(startDate.value);
    const daysCount = getDaysCountInCurrentMonth(startDate.value);

    if (!dayOfCurrentWeek || !daysCount) return [];

    // Дни предыдущего месяца, попадающие в первую неделю нового месяца. Необходимы для формирования корректного сдвига первого дня отображаемого месяца в "сетке"
    const leadingDays: TDataPickerDayState[] = Array.from({ length: dayOfCurrentWeek - 1 }).map(
      () => ({ value: null }));

    const daysOfCurrentMonth: TDataPickerDayState[] = Array.from({ length: daysCount }).map(
      (_, index: number) => {
        const day = index + 1;

        const date = `${year.value}-${month.value}-${day}`;

        const isSelected = getIsDateBetweenOrSame(selectedDate.value, date, date);
        const isDateInAvailableRange = getIsDateBetweenOrSame(date, minDate.value, maxDate.value);

        return {
          value: day,
          isSelected,
          isDisabled: !isDateInAvailableRange,
          isHovered: isDateInAvailableRange,
          isToday: isToday(date),
        };
      });

    return leadingDays.concat(daysOfCurrentMonth);
  });

  const monthTitle = computed(() => tt(`shared.months.${EMonth[month.value] as keyof typeof EMonth}`));

  return {
    days,
    monthTitle,
  };
};
