import app from './app';
import contractorInfo from './contractorInfo';
import filter from './filter';
import notifications from './notifications';
import pagination from './pagination';
import providers from './providers';
import rightPanel from './rightPanel';
import strategies from './strategies';
import tenants from './tenants';
import tenders from './tenders';
import timer from './timer';
import user from './user';

const modules = {
  app,
  contractorInfo,
  filter,
  notifications,
  pagination,
  providers,
  rightPanel,
  strategies,
  tenants,
  tenders,
  timer,
  user,
};

export default modules;
