import { ROUTE_TYPE } from '@/constants';
import { canBrowseTenantBaseSettingsByRole } from '@/domains/permissions/role/tenantBaseSettings';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';

import { EAccountingRouteName } from '../domain/constants';

const routes = [
  {
    path: '/sign_up',
    name: EAccountingRouteName.signUp,
    meta: { type: ROUTE_TYPE.unauthenticated },
    component: () => import('@/views/Infrastructure/SignUpView/index.vue'),
  },
  {
    path: '/sign_in',
    name: EAccountingRouteName.signIn,
    meta: { type: ROUTE_TYPE.unauthenticated },
    component: () => import('@/views/Infrastructure/SignInView/index.vue'),
  },
  {
    path: '/forgot_password',
    name: EAccountingRouteName.forgotPassword,
    meta: { type: ROUTE_TYPE.unauthenticated },
    component: () => import('@/views/Infrastructure/ForgotPassword/index.vue'),
  },
  {
    path: '/reset_passwords',
    name: EAccountingRouteName.resetPassword,
    meta: { type: ROUTE_TYPE.unauthenticated },
    component: () => import('@/views/Infrastructure/ResetPassword/index.vue'),
  },
  {
    path: '/account',
    name: EAccountingRouteName.account,
    meta: { type: ROUTE_TYPE.private },
    component: () => import('@/contexts/accountingContext/views/AccountView/index.vue'),
  },
  {
    path: '/tenant',
    name: EAccountingRouteName.tenant,
    component: () => import('@/contexts/accountingContext/views/TenantSettingsView/index.vue'),
    meta: { type: ROUTE_TYPE.private },
    redirect: () => (canBrowseTenantBaseSettingsByRole() ? '/tenant/settings' : '/tenant/integrations'),
    children: [
      buildProtectedRoute({
        path: '/tenant/settings',
        name: EAccountingRouteName.tenantSettings,
        meta: {
          type: ROUTE_TYPE.private,
          component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/CommonSettings/index.vue'),
          permissions: [canBrowseTenantBaseSettingsByRole],
        },
      }),
      {
        path: '/tenant/integrations',
        name: EAccountingRouteName.tenantIntegrations,
        component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/IntegrationsSettings/index.vue'),
      },
    ],
  },
];

export default routes;
