import { TErrorNotification } from '@/types';
import tt from '@/i18n/utils/translateText';

import notification from './notification';

const showErrorNotification = ({ options, message }: TErrorNotification = {}) => {
  notification.error({
    message: options?.message || message || tt('shared.error.general'),
    description: options?.description || tt('shared.retryLater'),
  });
};

export default showErrorNotification;
