import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/orderingContext/domain/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasDigitalQueuesPermission,
  hasMakeFreightRequestsPermission,
} from '@/domains/permissions/subscription';
import { canBrowseOrdersSectionByRole } from '@/domains/permissions/role/orders';
import { canBrowseFreightRequestsSectionByRole } from '@/domains/permissions/role/freightRequests';
import { canBrowseShipmentsByRole } from '@/domains/permissions/role/shipments';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const hasDigitalQueueSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasDigitalQueuesPermission());

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

const routes = [
  buildProtectedRoute({
    path: '/orders',
    name: ROUTE_NAME.ordersList,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasMakeFreightRequestsSubscription,
        () => canBrowseOrdersSectionByRole() || canBrowseFreightRequestsSectionByRole(),
      ],
      component: () => import('@/contexts/orderingContext/views/OrdersListView/index.vue'),
    },
    children: [
      {
        path: 'only_orders',
        name: ROUTE_NAME.ordersListOnlyOrders,
        meta: { type: ROUTE_TYPE.private },
        component: () => import('@/contexts/orderingContext/views/OrdersListView/components/OrdersListOnlyOrders/index.vue'),
      },
    ],
  }),
  {
    path: '/freight_requests/paywall',
    name: 'freightRequestsPaywall',
    meta: { type: ROUTE_TYPE.private },
    component: () => import('@/contexts/orderingContext/views/FreightRequestsPaywall/index.vue'),
  },
  {
    path: '/shipments/:token/booking',
    name: ROUTE_NAME.shipmentsInfoView,
    meta: { type: ROUTE_TYPE.public },
    component: () => import('@/contexts/orderingContext/views/ShipmentsInfoView/index.vue'),
  },
  buildProtectedRoute({
    path: '/shipments',
    name: ROUTE_NAME.shipmentsListView,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasDigitalQueueSubscription, canBrowseShipmentsByRole],
      component: () => import('@/contexts/orderingContext/views/ShipmentsListView/index.vue'),
    },
  }),
];

export default routes;
