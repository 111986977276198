import { createI18n } from 'vue-i18n';

import { ELocale } from '@/domains/constants';

import localeTextTranslationsEn from './utils/localeTextTranslationsEn';
import localeTextTranslationsRu from './utils/localeTextTranslationsRu';

export const i18n = createI18n({
  fallbackLocale: ELocale.ru,
  locale: ELocale.ru,
  messages: {
    ru: localeTextTranslationsRu,
    en: localeTextTranslationsEn,
  },
});
