import { TNotificationsState } from './types';

export const initialState = (): TNotificationsState => ({
  unreadCount: 0,
  isNotificationsListVisible: false,
  isTabWithUnreadNotifications: false,
  notifications: {
    ids: null,
    data: null,
  },
  isNotificationsLoading: false,
});
