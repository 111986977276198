/**
 * @param array
 * @param index
 * @returns
 * @description immutable
 */
const removeByIndex = <T = unknown>(array: T[], index: number): T[] => {
  const wrongIndex = typeof index !== 'number' || index < 0;
  const correctArray = Array.isArray(array);
  const indexGreaterLength = correctArray && index > array.length;
  if (!correctArray || wrongIndex || indexGreaterLength) return array;

  const itemsBefore = array.slice(0, index);
  const itemsAfter = array.slice(index + 1);

  return itemsBefore.concat(itemsAfter);
};

export default removeByIndex;
