<template>
  <component :is="logo" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';

import useAppTheme from '@/composables/application/useAppTheme';

export default defineComponent({
  name: 'AppLogo',
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { icons } = getThemeConfig();
    const logo = defineAsyncComponent(() => import(`../../assets/svg/logo/${icons.logo}.svg`));

    return { logo };
  },
});
</script>
