<template>
  <div class="date-picker-month">
    <div class="date-picker-month__title">
      <label>{{ monthTitle }}</label>
    </div>

    <div class="date-picker-month__days">
      <div
        v-for="(day, index) in days"
        :key="index"
        class="date-picker-month__day"
        :data-disabled="!!day.isDisabled"
        :data-selected="!!day.isSelected"
        :data-hovered="!!day.isHovered"
        @click="handleDayClick(day)"
      >
        <label v-if="day.value">
          {{ day.value }}
        </label>

        <div
          v-if="day.isToday"
          class="date-picker-month__today-mark"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  toRefs,
} from 'vue';

import { TDataPickerDayState, EMonth } from '@/ui/types';

import { useView } from './application/useView';

export default defineComponent({
  name: 'DatePickerMonth',
  components: {},
  props: {
    month: {
      type: Number as PropType<EMonth>,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    minDate: {
      type: String,
      required: true,
    },
    maxDate: {
      type: String,
      required: true,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const {
      month,
      year,
      selectedDate,
      minDate,
      maxDate,
    } = toRefs(props);

    const {
      days,
      monthTitle,
    } = useView({
      month,
      year,
      selectedDate,
      minDate,
      maxDate,
    });

    const handleDayClick = (day: TDataPickerDayState) => {
      if (day.isDisabled || !day.value) return;

      emit('select', day.value);
    };

    return {
      days,
      monthTitle,

      handleDayClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
