import { ref } from 'vue';

import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { EBillingPlanSelectionSteps } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/constants';
import { TChangePlanSelectionStepFunction } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/types';

export const useChangePlan = (changePlanSelectionStep: TChangePlanSelectionStepFunction) => {
  const planCode = ref<EBillingPlanCode | null>(null);

  const handlePlanChange = (planCodeValue: EBillingPlanCode) => {
    planCode.value = planCodeValue;

    changePlanSelectionStep(EBillingPlanSelectionSteps.changeTenantInfo);
  };

  return {
    planCode,

    handlePlanChange,
  };
};
