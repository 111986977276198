<template>
  <div class="tenant-menu">
    <div
      class="tenant-menu__info"
      @click.stop="handleTenantInfoToggle"
    >
      <div v-if="!isCollapsed">
        <div
          class="tenant-menu__account"
        >
          <span class="tenant-menu__name">
            <EllipsisLabel
              :content="currentTenant?.name || tt('sider.info.tenantNotSelected')"
            />
          </span>
          <span class="tenant-menu__email">
            <EllipsisLabel
              :content="email"
            />
          </span>
        </div>
      </div>
      <ChevronRight
        class="tenant-menu__icon"
        :class="{ 'tenant-menu__icon_rotate': showTenantInfo }"
      />
    </div>

    <div
      v-if="showTenantInfo"
      v-click-outside="handleTenantInfoToggle"
      class="tenant-menu__dropdown"
      :class="{ 'tenant-menu__dropdown_collapsed': isCollapsed }"
    >
      <div class="tenant-menu__dropdown-header">
        <h1 class="tenant-menu__dropdown-title">
          <span v-if="fullName">
            {{ fullName }}
          </span>
          <span v-else>
            {{ tt('shared.firstName') }}: <EmptyLabel />
          </span>
        </h1>
        <span class="tenant-menu__dropdown-role">
          <span v-if="employeeRoles">{{ employeeRoles }}</span>
          <span
            v-else
            class="tenant-menu__dropdown-role"
          >
            {{ tt('shared.role') }}: <EmptyLabel :declension="ELabelDeclension.feminine" />
          </span>
        </span>
        <Button
          v-if="showEmployeeProfileLink"
          type="link"
          class="tenant-menu__dropdown-profile"
          @click="handleEmployeeProfileClick"
        >
          {{ tt('sider.info.employeeProfile') }}
        </Button>
        <Button
          v-if="isAdmin"
          type="link"
          class="tenant-menu__dropdown-profile"
          @click="handleAdminPanelClick"
        >
          {{ tt('sider.info.adminPanel') }}
        </Button>
      </div>
      <div
        v-if="showOrganizations"
        class="tenant-menu__organizations"
      >
        <h2
          class="tenant-menu__organizations-title"
          :class="{ 'tenant-menu__organizations-title_scroll': organizationsBorderTop }"
        >
          {{ tt('sider.info.organizations') }}
        </h2>
        <div
          class="tenant-menu__organizations-list"
          @scroll.passive="handleOrganizationsScroll"
        >
          <div v-if="availableUserTenants.length">
            <div
              v-for="(tenant, index) in availableUserTenants"
              :key="index"
              class="tenant-menu__organization"
              @click="handleSelectTenant(tenant)"
            >
              <span class="tenant-menu__organization-title">
                <EllipsisLabel :content="tenant.name" />
              </span>
              <CheckSvg
                v-if="tenant.id === currentTenant?.id"
                class="tenant-menu__organization-icon"
              />
            </div>
          </div>
          <span v-else>{{ tt('sider.info.noOrganizations') }}</span>
        </div>
        <Button
          v-if="isAddTenantButtonShown"
          type="link"
          class="tenant-menu__add-organization"

          :class="{ 'tenant-menu__add-organization_scroll': organizationsBorderBottom }"
          @click="handleAddTenantClick"
        >
          {{ tt('sider.info.addOrganization') }}
        </Button>
      </div>
      <div class="tenant-menu__localization">
        <LocalizationButton />
      </div>
      <div class="tenant-menu__dropdown-footer">
        <span class="tenant-menu__dropdown-link">
          <SmartLink href="https://docs.tms.ru/blog/tags/обновления/">
            {{ tt('sider.info.whatIsNew') }}
          </SmartLink>
        </span>
        <span
          v-if="showSettingsButton"
          class="tenant-menu__dropdown-link"
          @click="handleSettingsClick"
        >
          {{ tt('sider.info.settings') }}
        </span>
        <span
          class="tenant-menu__dropdown-link"
          @click="handleAccountClick"
        >
          {{ tt('sider.info.myAccount') }}
        </span>
        <span
          class="tenant-menu__dropdown-link"
          @click="handleLogoutClick"
        >
          {{ tt('sider.info.logOff') }}
        </span>
      </div>
    </div>
    <AddTenantDrawer
      v-if="isAddTenantDrawerOpened"
      visible
      @close="handleAddTenantDrawerClose"
      @success="handleAddTenantSuccess"
    />
  </div>
</template>

<script lang="ts">
import { Button } from 'ant-design-vue';
import {
  defineComponent,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import SmartLink from '@/components/SmartLink/index.vue';
import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import EllipsisLabel from '@/components/EllipsisLabel/index.vue';
import EmptyLabel from '@/components/EmptyLabel/index.vue';
import AddTenantDrawer from '@/contexts/accountingContext/components/AddTenantDrawer/index.vue';
import { notification } from '@/utils';
import { ELabelDeclension } from '@/components/EmptyLabel/constants';
import { RESET_STATE } from '@/store/modules/notifications/mutation-types';
import { useDigitalQueueSettingsStore } from '@/stores/digitalQueueSettings';
import ChevronRight from '@/assets/svg/16x16/chevron-right.svg';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import { useCustomizationStore } from '@/stores/customization';
import { useViewSettingsStore } from '@/stores/viewSettings';
import { useSearchStore } from '@/stores/search';
import { useExternalDigitalQueuesStore } from '@/stores/externalDigitalQueues';
import { RESET_ALL_PAGINATION } from '@/store/modules/pagination/mutation-types';
import { TTenant } from '@/types';
import { useFoldersStore } from '@/stores/folders';

import { useView } from './application/useView';
import LocalizationButton from '../LocalizationButton/index.vue';

const ORGANIZATION_LIST_HEIGHT = 162;

export default defineComponent({
  name: 'TenantMenu',
  components: {
    CheckSvg,
    ChevronRight,
    Button,
    AddTenantDrawer,
    EllipsisLabel,
    EmptyLabel,
    SmartLink,
    LocalizationButton,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['collapse'],
  setup() {
    const store = useStore();
    const digitalQueueSettingsStore = useDigitalQueueSettingsStore();
    const router = useRouter();
    const customizationStore = useCustomizationStore();
    const viewSettingsStore = useViewSettingsStore();
    const searchStore = useSearchStore();
    const externalDigitalQueuesStore = useExternalDigitalQueuesStore();
    const foldersStore = useFoldersStore();
    const showTenantInfo = ref(false);
    const isAddTenantDrawerOpened = ref(false);
    const organizationsBorderTop = ref(false);
    const organizationsBorderBottom = ref(false);

    const {
      isAddTenantButtonShown,
      currentTenant,
      employee,
      availableUserTenants,
      fullName,
      email,
      showOrganizations,
      showSettingsButton,
      employeeRoles,
      showEmployeeProfileLink,
    } = useView();

    const handleTenantInfoToggle = () => {
      showTenantInfo.value = !showTenantInfo.value;
    };

    const handleAccountClick = () => {
      router.push('/account');
    };

    const handleLogoutClick = () => {
      handleTenantInfoToggle();
      store.dispatch('app/logout');
    };

    const handleEmployeeProfileClick = () => {
      if (!employee.value?.id) return;
      router.push(`/employees/${employee.value.id}/information`);
    };

    const handleSelectTenant = (tenant: TTenant) => {
      if (currentTenant.value?.id === tenant.id) return;

      // При смене tenant`a нужно сбросить все связанные с ним данные стора
      handleTenantInfoToggle();
      store.dispatch('tenants/selectTenant', { tenant });
      store.commit(`pagination/${RESET_ALL_PAGINATION}`);
      store.dispatch('filter/resetState');
      foldersStore.$reset();
      digitalQueueSettingsStore.$reset();

      store.commit(`notifications/${RESET_STATE}`);
      customizationStore.$reset();
      viewSettingsStore.$reset();
      searchStore.$reset();
      externalDigitalQueuesStore.$reset();
    };

    const handleAddTenantDrawerClose = () => {
      isAddTenantDrawerOpened.value = false;
    };

    const handleAddTenantClick = () => {
      handleTenantInfoToggle();
      isAddTenantDrawerOpened.value = true;
    };

    const handleAddTenantSuccess = () => {
      handleAddTenantDrawerClose();
      notification.success({ message: tt('sider.info.organizationAddSuccessful') });
    };

    const handleSettingsClick = () => {
      handleTenantInfoToggle();
      router.push('/tenant');
    };

    const handleAdminPanelClick = () => {
      router.push('/admin');
    };

    const handleOrganizationsScroll = (e: any) => {
      organizationsBorderTop.value = e.target.scrollTop > 0;
      organizationsBorderBottom.value = e.target.scrollHeight !== (e.target.scrollTop + ORGANIZATION_LIST_HEIGHT);
    };

    return {
      availableUserTenants,
      fullName,
      currentTenant,
      email,
      showTenantInfo,
      isAddTenantDrawerOpened,
      showOrganizations,
      showSettingsButton,
      organizationsBorderTop,
      organizationsBorderBottom,
      employeeRoles,
      showEmployeeProfileLink,
      ELabelDeclension,
      isAddTenantButtonShown,

      handleTenantInfoToggle,
      handleSelectTenant,
      handleAccountClick,
      handleSettingsClick,
      handleLogoutClick,
      handleEmployeeProfileClick,
      handleAddTenantDrawerClose,
      handleAddTenantClick,
      handleOrganizationsScroll,
      handleAddTenantSuccess,
      handleAdminPanelClick,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
