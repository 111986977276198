// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

type TGetDateDiff = {
  firstDate: number | TDateAppFormat | null | undefined | string,
  secondDate: number | TDateAppFormat | null | undefined | string,
  timeZone?: string,
  unit?: 'day' | 'week' | 'month' | 'year' | 'hour' | 'minute' | 'second',
};

const getDateDiff = ({
  firstDate,
  secondDate = dayjs(),
  timeZone,
  unit,
}: TGetDateDiff): number => (timeZone ? dayjs.tz(firstDate, timeZone) : dayjs(firstDate))
  .diff(timeZone ? dayjs.tz(secondDate, timeZone) : dayjs(secondDate), unit);

export default getDateDiff;
