import { sendApiRequest } from '@/api/sendApiRequest';
import { httpMethods } from '@/api';
import {
  TLookupCustomManifestItem,
  TLookupCustomManifestResponse,
  TPaginationForRequest,
} from '@/types';
import { TFuncApplySetServiceParams } from '@/components/Filter/types';
import {
  TTargetOfferStageCreateRequest,
  TReductionStageCreateRequest,
  TSpotOfferStageCreateRequest,
  TExecutorsLookupsResponse,
  TStrategy,
  TAutomationCreationRequest,
  TAutomationDetailed,
  TAutomationsResponse,
  TAutomationResponse,
  TChainStepsResponse,
  TAutomationEditingRequest,
  TSchemeCreationRequest,
  TSchemesResponse,
  TStrategyEditingRequest,
  TTargetOfferTemplate,
  TSpotOfferTemplate,
  TReductionTemplate,
  TFreeOfferingTemplate,
  TInvitedParticipantsResponse,
  TProvider,
  TProviderConnection,
  TExecutorsLookupPriceAnalytics,
  TAutoBiddingSettings,
  TCreateLookupAtisuCargoPayload,
  TAtisuContact,
  TLookupAtisuCargo,
  TPotentialExecutor,
  TFeedRequest,
  TChainsResponse,
  TExecutorsLookupStatisticsResponse,
  TParticipationStateResponse,
  TGetGroupsAndPartnersForAgreementParams,
  TGetGroupsAndPartnersForOrder,
  TFreeOfferingStageCreateRequest,
  TFeedOrdersResponse,
  TGroupOrPartnerForOrderResponse,
  TDivisibleReductionPossibleBidsRequest,
  TDivisibleReductionPossibleBidsResponse,
  TGetGroupsAndPartners,
} from '@/contexts/executorsLookupContext/domain/types';
import { TQuotaStageCreateRequest } from '@/contexts/quotaContext/domain/types';
import { TDirectionsResponse } from '@/contexts/directionsContext/domain/types';
import { TReductionForCustomerResponse } from '@/contexts/reductionContext/domain/types';
import { TReachedResponse } from '@/api/types';

import { ELookupStrategyAction } from '../domain/constants';
import { ABORT_SIGNAL_NAME } from './constants';

export function fetchFeed({
  activeTab,
  currentPage,
  filterId,
  referenceNumber,
  lookupMethodType,
  pageItems,
  sort,
  sortBy,
  modality,
  executorsLookupIds,
} = {
  pageItems: null,
  currentPage: null,
} as TPaginationForRequest & TFeedRequest,
useAbortController: boolean = true) {
  return sendApiRequest<TFeedOrdersResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/feeds/${activeTab}`,
    params: {
      items: pageItems,
      page: currentPage,
      filterId,
      lookupMethodType,
      sort,
      sortBy,
      referenceNumber,
      modality,
      executorsLookupIds,
    },
    signalName: useAbortController ? ABORT_SIGNAL_NAME.executorsLookupContextFetchFeed : '',
  });
}

export function fetchExecutorOrder(executorLookupId: number, routeByLookupMethod: string) {
  return sendApiRequest<TReductionForCustomerResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/${routeByLookupMethod}/${executorLookupId}`,
  });
}

export const getParticipationState = (typeOffer: string, executorsLookupId?: number) => {
  if (!executorsLookupId) return Promise.reject();

  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/${typeOffer}/${executorsLookupId}/participation_state`,
  });
};

export const getGroupingParticipationState = (
  lookupMethodUrl: string,
  ids: Array<string | number>,
) => sendApiRequest<TParticipationStateResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/${lookupMethodUrl}/participation_states`,
  params: { ids: ids.join() },
});

export const getParticipationStateForExternalReduction = (executorsLookupId: number) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/external/reductions/${executorsLookupId}/participation_state`,
});

export function getParticipationStateForExternalSpotOffer(executorsLookupId: number) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/external/spot_offers/${executorsLookupId}/participation_state`,
  });
}

export function fetchExecutorsLookupPriceAnalytic({ executorsLookupId }: { executorsLookupId: number }) {
  return sendApiRequest<TExecutorsLookupPriceAnalytics>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/price_analytic`,
  });
}

export function fetchExternalExecutorsLookupPriceAnalytic({ executorsLookupId }: { executorsLookupId: number }) {
  return sendApiRequest<TExecutorsLookupPriceAnalytics>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/price_analytic`,
  });
}

export function fetchExecutorsLookups(options: any, query?: string | null) {
  if (!options) return Promise.reject();

  const {
    pageItems,
    currentPage,
    lookupMethodType,
  } = options;

  const distributedByQuota = !!options?.distributedByQuota;

  return sendApiRequest<TExecutorsLookupsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/executors_lookups',
    params: {
      items: pageItems,
      page: currentPage,
      lookupMethodType,
      query,
      distributedByQuota,
    },
  });
}

export function getGroupsAndPartners({
  query,
  groupIds = [],
  orderIids = [],
  onlyFromGroups,
  withoutGroups,
}: TGetGroupsAndPartners) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/autocomplete/potential_participants',
    params: {
      query,
      groupIds,
      orderIids,
      onlyFromGroups,
      withoutGroups,
    },
  });
}

export function getGroupsAndPartnersForOrder({
  groupIds = [],
  onlyFromGroups,
  orderIid,
  query,
  withoutGroups = false,
}: TGetGroupsAndPartnersForOrder) {
  const params: {
    groupIds?: string[] | null[],
    onlyFromGroups?: boolean,
    query: string | null,
    withoutGroups?: boolean,
  } = {
    groupIds,
    onlyFromGroups,
    query,
  };

  if (withoutGroups) params.withoutGroups = withoutGroups;

  return sendApiRequest<TGroupOrPartnerForOrderResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/autocomplete/orders/${orderIid}/potential_participants`,
    params,
  });
}

export function getGroupsAndPartnersForAgreement({
  executionRequestAgreementId,
  query,
  groupIds = [],
  onlyFromGroups,
  withoutGroups = false,
}: TGetGroupsAndPartnersForAgreementParams) {
  const params: {
    groupIds?: string[] | null[],
    onlyFromGroups?: boolean,
    query: string | null,
    withoutGroups?: boolean,
  } = {
    groupIds,
    onlyFromGroups,
    query,
  };

  if (withoutGroups) params.withoutGroups = withoutGroups;

  return sendApiRequest<TGroupOrPartnerForOrderResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/autocomplete/execution_request_agreements/${executionRequestAgreementId}/potential_agreement_participants`,
    params,
  });
}

export function getLookupsCounters() {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/lookup_methods_counters',
  });
}

export function fetchLookupStrategies(
  { pageItems, currentPage } = {
    pageItems: null as null | number,
    currentPage: null as null | number,
  },
  activeTab: string,
) {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/lookup_strategies',
    params: {
      items: pageItems,
      page: currentPage,
      activeTab,
    },
  });
}

export function createStrategy(payload: any) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/lookup_strategies',
    payload,
  });
}

export function fetchLookupStrategiesCounters() {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/lookup_strategies_counters',
  });
}

export function getStrategy(id: string) {
  return sendApiRequest<TStrategy>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/lookup_strategies/${id}`,
  });
}

export function setStrategyStatuses(id: string, action: ELookupStrategyAction) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/lookup_strategies/${id}/statuses`,
    payload: { action },
  });
}

export const createAutomation = (
  lookupStrategyId: string,
  payload: TAutomationCreationRequest,
) => sendApiRequest<TAutomationDetailed>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}/automations`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editAutomation = (automationId: string, payload: TAutomationEditingRequest) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/automations/${automationId}`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const archiveAutomation = (automationId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/automations/${automationId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getAutomations = (lookupStrategyId: string) => sendApiRequest<TAutomationsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}/automations`,
});

export const getAutomation = (automationId: string) => sendApiRequest<TAutomationResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/automations/${automationId}`,
});

export const changeStepPosition = (
  automationId: string,
  automationScriptId: string,
  scriptStepId: string,
  payload: { position: number }) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/${scriptStepId}/position`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addQuotaStepToChain = (
  automationId: string,
  automationScriptId: string,
  payload: TQuotaStageCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/quota_distribution`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addReductionStepToChain = (
  automationId: string,
  automationScriptId: string,
  payload: TReductionStageCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/reduction`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addSpotOfferStepToChain = (
  automationId: string,
  automationScriptId: string,
  payload: TSpotOfferStageCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/spot_offer`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addTargetOfferStepToChain = (
  automationId: string,
  automationScriptId: string,
  payload: TTargetOfferStageCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/target_offer`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const addFreeOfferingStepToChain = (
  automationId: string,
  automationScriptId: string,
  payload: TFreeOfferingStageCreateRequest) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/free_offering`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getStepsChain = (
  automationId: string,
  automationScriptId: string) => sendApiRequest<TChainStepsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps`,
});

export const deleteStepFromChain = (
  automationId: string,
  automationScriptId: string,
  stepId: string) => sendApiRequest<TChainStepsResponse>({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/script_steps/${stepId}`,
});

export const addStepsChainDraft = (automationId: string) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/init`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const commitStepsChain = (automationId: string, automationScriptId: string) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/automations/${automationId}/scripts/${automationScriptId}/commit`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getAvailableDirectionsForAutomation = (automationId: string) => sendApiRequest<TDirectionsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/automations/${automationId}/available_directions`,
});

export const createScheme = (
  lookupStrategyId: string,
  payload: TSchemeCreationRequest,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}/schemes`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getSchemes = (lookupStrategyId: string) => sendApiRequest<TSchemesResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}/schemes`,
});

export const activationScheme = (schemeId: string) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/schemes/${schemeId}/activation`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const bypassScheme = (schemeId: string) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/schemes/${schemeId}/bypass`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getDirectionsForScheme = (schemeId: string) => sendApiRequest<TDirectionsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/schemes/${schemeId}/directions`,
});

export const deleteScheme = (lookupStrategyId: string, schemeId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}/schemes/${schemeId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editStrategy = (lookupStrategyId: string, payload: TStrategyEditingRequest) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/lookup_strategies/${lookupStrategyId}`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function getTargetOfferTemplate(id: string) {
  return sendApiRequest<TTargetOfferTemplate>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/target_offer/templates/${id}`,
  });
}

export function getSpotOfferTemplate(id: string) {
  return sendApiRequest<TSpotOfferTemplate>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/spot_offer/templates/${id}`,
  });
}

export function getReductionTemplate(id: string) {
  return sendApiRequest<TReductionTemplate>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/reduction/templates/${id}`,
  });
}

export function getFreeOfferingTemplate(id: string) {
  return sendApiRequest<TFreeOfferingTemplate>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/free_offering/templates/${id}`,
  });
}

export const getInvitedParticipants = (executorsLookupId: number) => sendApiRequest<TInvitedParticipantsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/invited_participants`,
});

export const getProviders = () => sendApiRequest<TProvider[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/providers',
});

export const getProviderConnections = () => sendApiRequest<TProviderConnection[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/provider_connections',
});

export function fetchAutoBiddingSettings(executorsLookupId: number) {
  return sendApiRequest<TAutoBiddingSettings>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/external/reductions/${executorsLookupId}/auto_bidding`,
  });
}

export const updateAutoBiddingSettings = (
  executorsLookupId: number,
  payload: { enabled: boolean, limit: number, step: number },
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/external/reductions/${executorsLookupId}/auto_bidding`,
  payload,
});

export const createProviderConnections = (
  payload: { providerId: string, credentials: Record<string, string> },
) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/provider_connections',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload,
});

export const updateProviderConnections = (
  providerConnectionId: number,
  payload: { credentials: Record<string, string> },
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/provider_connections/${providerConnectionId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload,
});

export const providerConnectionsDiscard = (providerConnectionId: number) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/provider_connections/${providerConnectionId}/discard`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const providerConnectionsUndiscard = (providerConnectionId: number) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/provider_connections/${providerConnectionId}/undiscard`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const applyFeedsNewFilters = ({ filters, operator }: TFuncApplySetServiceParams) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/feeds/new/filters',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload: {
    filters,
    operator,
  },
});

export const applyFeedsBiddingFilters = ({ filters, operator }: TFuncApplySetServiceParams) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/feeds/bidding/filters',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload: {
    filters,
    operator,
  },
});

export const applyFeedsFinishedFilters = ({ filters, operator }: TFuncApplySetServiceParams) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/feeds/finished/filters',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload: {
    filters,
    operator,
  },
});

export const getAtisuContacts = () => sendApiRequest<TAtisuContact[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/atisu_contacts',
});

export const getLookupAtisuCargo = (executorsLookupId: number) => sendApiRequest<TLookupAtisuCargo>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/atisu_cargo`,
});

export const createLookupAtisuCargo = (
  executorsLookupId: number,
  payload: TCreateLookupAtisuCargoPayload,
) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/atisu_cargo`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload,
});

export const getExternalLookupAtisuCargo = (executorsLookupId: number) => sendApiRequest<TLookupAtisuCargo>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/atisu_cargo`,
});

export const createExternalLookupAtisuCargo = (
  executorsLookupId: number,
  payload: TCreateLookupAtisuCargoPayload,
) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/atisu_cargo`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
  payload,
});

export const getPotentialExecutors = (executorsLookupId: number) => sendApiRequest<TPotentialExecutor[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/potential_executors`,
});

export const savePotentialExecutors = (executorsLookupId: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/potential_executors`,
});

export const getExternalPotentialExecutors = (executorsLookupId: number) => sendApiRequest<TPotentialExecutor[]>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/potential_executors`,
});

export const saveExternalPotentialExecutors = (executorsLookupId: number) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/potential_executors`,
});

export const getChains = (
  { executorsLookupId }: { executorsLookupId: number },
) => sendApiRequest<TChainsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/chains`,
});

export const getExecutorsLookupStatistics = (
  { executorsLookupId }: { executorsLookupId: number },
) => sendApiRequest<TExecutorsLookupStatisticsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/external/executors_lookups/${executorsLookupId}/statistics`,
});

export const getActualLookupExtraServices = (executorsLookupId: number) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/executors_lookups/${executorsLookupId}/extra_services/actual`,
});

export const getLookupSpotOfferFormManifest = (): Promise<
TReachedResponse<TLookupCustomManifestResponse>
> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/automations/script_steps/spot_offer/custom_manifest',
});

export const getLookupTargetOfferFormManifest = (): Promise<
TReachedResponse<TLookupCustomManifestResponse>
> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/automations/script_steps/target_offer/custom_manifest',
});

export const getLookupReductionFormManifest = (): Promise<
TReachedResponse<TLookupCustomManifestItem[]>
> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/automations/script_steps/reduction/custom_manifest',
});

export const getLookupQuotaFormManifest = (): Promise<
TReachedResponse<TLookupCustomManifestResponse>
> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/automations/script_steps/quotas_distribution/custom_manifest',
});

export const getLookupFreeOfferingFormManifest = (): Promise<
TReachedResponse<TLookupCustomManifestResponse>
> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/automations/script_steps/free_offering/custom_manifest',
});

export const getLookupDivisibleReductionPossibleBids = ({
  executorsLookupId,
  requestedQuantity,
  freeQuantityPrice,
}: TDivisibleReductionPossibleBidsRequest): Promise<
TReachedResponse<TDivisibleReductionPossibleBidsResponse>> => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/reductions/${executorsLookupId}/possible_bids`,
  params: {
    requestedQuantity,
    freeQuantityPrice,
  },
});
