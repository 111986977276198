import { useRoute } from 'vue-router';

import { EExperimentalFeatures } from '@/domains/constants';
import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

export const getIsBillingPaywallMessageShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const hasTenants = store.state.tenants.userTenants?.length > 0;

  const isLicenseSettingsForInstanceWithoutBillingAvailable = checkFeatureIsEnabled(
    EExperimentalFeatures.licenseSettingsForInstanceWithoutBilling,
  );

  const isBillingValidFrom1MarchAvailable = checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March);

  const hasInstanceLicense = isLicenseSettingsForInstanceWithoutBillingAvailable
    && !!store.state.app.appSettings?.licenseSettings;

  const currentTenantSubscriptions = store.state.tenants.currentTenantInfo?.subscriptions || [];

  const hasSubscriptionWithActiveStatus = !!currentTenantSubscriptions
    .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);

  const isCarrierStartSubscription = currentTenantSubscriptions[0]?.planCode === EBillingPlanCode.carrierStart;

  // инстанс может работать либо по лицензии, либо с биллингом
  return !hasInstanceLicense
    && isBillingValidFrom1MarchAvailable
    && hasTenants
    && isPrivateRoute
    && (!hasSubscriptionWithActiveStatus || isCarrierStartSubscription);
};
