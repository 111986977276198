import { defineStore } from 'pinia';
import { reactive } from 'vue';

import {
  isNumber,
  replaceByIndex,
  showErrorNotification,
} from '@/utils';
import {
  TTableColumn,
  TQuickAction,
  TEntity,
  TDomainError,
  TFolderInfoResponse,
} from '@/types';
import {
  TCustomTableSort,
  TTableConfigLoaded,
  TTableConfigLoadedItem,
} from '@/components/CustomTableLayout/types';
import { getTableSettings, TViewSettingsConfig } from '@/services';
import { TReachedResponse } from '@/api/types';
import { getFolderInfo } from '@/contexts/orderingContext/services';
import { EOrderFolderSlug } from '@/contexts/orderingContext/domain/constants';

import { EFoldersSection } from './constants';
import {
  TFoldersStore,
  TFoldersState,
  TFolderWithSettings,
} from './types';

export const useFoldersStore = defineStore('TMS_FOLDERS', (): TFoldersStore => {
  const foldersState = reactive<TFoldersState>({
    [EFoldersSection.matricesList]: {
      currentFolderId: null,
      folders: [],
    },
    [EFoldersSection.orderList]: {
      currentFolderId: null,
      currentOrderIid: null,
      folders: [],
    },
    [EFoldersSection.referencesList]: {
      currentFolderId: null,
      folders: [],
    },
  });

  const getCurrentSectionFolder = (sectionFolders: TFolderWithSettings[], folderIdToFind: string) => sectionFolders
    .find((folder: TFolderWithSettings) => folder.id === folderIdToFind) || null;

  const setFolders = (section: EFoldersSection, newFolders: TEntity[]) => {
    if (!section) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!newFolders.length) {
      console.warn('Folders is empty.');
      return;
    }

    foldersState[section].folders = newFolders.map((newFolder: TEntity) => {
      const currentFolder = getCurrentSectionFolder(foldersState[section].folders, newFolder.id);
      if (!currentFolder) {
        // TODO: избавиться от sort, после задачи TMS-7138
        const defaultFolderSettings = {
          columnsSettings: [],
          sort: {
            sort: null,
            sortBy: null,
          },
          quickActions: [],
        };
        return {
          ...newFolder,
          ...defaultFolderSettings,
        };
      }

      return {
        ...currentFolder,
        ...newFolder,
      };
    });
  };

  const setCurrentFolderId = (section: EFoldersSection, currentFolderId: string) => {
    if (!section) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!currentFolderId) {
      console.warn(`There is no section with "${currentFolderId}" id.`);
      return;
    }
    foldersState[section].currentFolderId = currentFolderId;
  };

  const setCurrentOrderIid = (orderIid: number | null) => {
    if (!orderIid) return;
    if (!isNumber(orderIid)) {
      console.warn('OrderIid must be a number.');
      return;
    }
    foldersState[EFoldersSection.orderList].currentOrderIid = orderIid;
  };

  const setColumnsSettings = (
    section: EFoldersSection,
    columnsSettings: TTableColumn[] | TTableConfigLoadedItem[]) => {
    if (!section) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!foldersState[section].currentFolderId) {
      return;
    }

    const currentFolder = getCurrentSectionFolder(
      foldersState[section].folders,
      foldersState[section].currentFolderId as string);

    if (!currentFolder) {
      return;
    }

    const currentFolderIndex = foldersState[section].folders
      .findIndex((folder: TFolderWithSettings) => folder.id === currentFolder.id);

    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        columnsSettings,
      };

      foldersState[section].folders = replaceByIndex(
        foldersState[section].folders,
        currentFolderIndex,
        updatedCurrentFolder);
    }
  };

  // TODO: избавиться от setSort, после задачи TMS-7138
  const setSort = (section: EFoldersSection, sort: TCustomTableSort) => {
    if (!section) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!foldersState[section].currentFolderId) {
      return;
    }

    const currentFolder = getCurrentSectionFolder(
      foldersState[section].folders,
      foldersState[section].currentFolderId as string);

    if (!currentFolder) {
      return;
    }

    const currentFolderIndex = foldersState[section].folders
      .findIndex((folder: TFolderWithSettings) => folder.id === currentFolder.id);

    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        sort,
      };
      foldersState[section].folders = replaceByIndex(
        foldersState[section].folders,
        currentFolderIndex,
        updatedCurrentFolder);
    }
  };

  const setQuickActions = (section: EFoldersSection, quickActions: TQuickAction[]) => {
    if (!section) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!foldersState[section].currentFolderId) {
      return;
    }

    const currentFolder = getCurrentSectionFolder(
      foldersState[section].folders,
      foldersState[section].currentFolderId as string);

    if (!currentFolder) {
      return;
    }

    const currentFolderIndex = foldersState[section].folders
      .findIndex((folder: TFolderWithSettings) => folder.id === currentFolder.id);

    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        quickActions,
      };
      foldersState[section].folders = replaceByIndex(
        foldersState[section].folders,
        currentFolderIndex,
        updatedCurrentFolder);
    }
  };

  const loadTableSettings = (
    config: TViewSettingsConfig,
    section: EFoldersSection,
    withoutSettingColumns: boolean = false) => getTableSettings(config)
    .then(({ data }: TReachedResponse<{ columns: TTableConfigLoaded }>) => {
      if (!withoutSettingColumns) {
        setColumnsSettings(section, data.columns);
      }
      return data.columns;
    })
    .catch((error: TDomainError) => {
      showErrorNotification(error);
      return [];
    });

  const loadOrderFolderInfo = (orderFolderSlug: EOrderFolderSlug) => getFolderInfo(orderFolderSlug)
    .then((response: TReachedResponse<TFolderInfoResponse>) => {
      if (!response.data) return;
      setQuickActions(EFoldersSection.orderList, response.data.quickActions);
    })
    .catch(showErrorNotification);

  const $reset = () => {
    foldersState[EFoldersSection.matricesList] = {
      currentFolderId: null,
      folders: [],
    };
    foldersState[EFoldersSection.orderList] = {
      currentFolderId: null,
      currentOrderIid: null,
      folders: [],
    };
    foldersState[EFoldersSection.referencesList] = {
      currentFolderId: null,
      folders: [],
    };
  };

  return {
    foldersState,
    loadTableSettings,
    loadOrderFolderInfo,
    setSort,
    setFolders,
    setQuickActions,
    setCurrentFolderId,
    setCurrentOrderIid,
    setColumnsSettings,
    getCurrentSectionFolder,
    $reset,
  };
}, { persist: true });
