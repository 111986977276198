import QueueSvg from '@/assets/svg/16x16/queue.svg';
import MenuSvg from '@/assets/svg/16x16/menu.svg';
import tt from '@/i18n/utils/translateText';

export enum EDigitalQueuePeriod {
  day = 'day',
  week = 'week',
}

export enum EDigitalQueuePageType {
  booking = 'booking',
  schedule = 'schedule',
}

export enum EDigitalQueuePageView {
  timeline = 'timeline',
  table = 'table',
}

export const digitalQueuePageViewFilters = [
  {
    key: EDigitalQueuePageView.timeline,
    label: '',
    icon: QueueSvg,
  },
  {
    key: EDigitalQueuePageView.table,
    label: '',
    icon: MenuSvg,
  },
];

export enum EScheduleAllowedTargets {
  run = 'run',
  shipment = 'shipment',
  runAndShipment = 'runAndShipment',
}

export const ALLOWED_TARGETS_TRANSLATION = {
  [EScheduleAllowedTargets.run]: tt('shared.runs.declension1'),
  [EScheduleAllowedTargets.shipment]: tt('shared.shipment'),
  [EScheduleAllowedTargets.runAndShipment]: tt('shared.runAndShipment'),
};

export enum EDigitalQueueLimitType {
  fromCreation = 'from_creation',
  beforeStart = 'before_start',
}

export enum EDigitalQueueBookingWithOrderFormFields {
  gateId = 'gateId',
  dateTime = 'dateTime',
  comment = 'comment',
  freightRequestIid = 'freightRequestIid',
  transitPointId = 'transitPointId',
  timeslots = 'timeslots',
}

export enum EDigitalQueueBookingWithShipmentFormFields {
  shipmentIid = 'shipmentIid',
  address = 'address',
  customer = 'customer',
}

export enum EDigitalQueueBookingWithoutOrderFormFields {
  driver = 'driver',
  powerOfAttorney = 'powerOfAttorney',
  truck = 'truck',
  trailer = 'trailer',
}

export enum EBookingWorkflowStateSlug {
  registered = 'registered', // Запланировано
  registrationIsNotCompleted = 'registration_is_not_completed', // Зарезервировано
}

// условный круглосуточный режим работы (так как в датапикере нельзя выбрать 24:00)
export const ROUND_THE_CLOCK_TIME = '23:59';

export enum EFormCollapsedFields {
  bookingTimeRestriction = 'bookingTimeRestriction',
  revocationTimeRestriction = 'revocationTimeRestriction',
  rebookingTimeRestriction = 'rebookingTimeRestriction',
  revocationShipmentsTimeRestriction = 'revocationShipmentsTimeRestriction',
  rebookingShipmentsTimeRestriction = 'rebookingShipmentsTimeRestriction',
  reservationsTimeRestriction = 'reservationsTimeRestriction',
}

export enum EBookingSettingsTabs {
  run = 'run',
  shipment = 'shipment',
}

export enum EBookingType {
  withOrder = 'withOrder',
  withoutOrder = 'withoutOrder',
  withShipment = 'withShipment',
}
