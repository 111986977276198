<template>
  <ControlWrapper
    ref="controlWrapperRef"
    class="editor"
    tabindex="0"
    :size="size"
    :isInvalid="isInvalid"
    :disabled="disabled"
    :hasBorder="hasBorder"
    @focus="focusEditor"
  >
    <div
      v-if="!readonly"
      class="editor__toolbar"
    >
      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        :isActive="isBoldMarkActive"
        :disabled="disabled"
        @click="handleBoldMarkButtonClick"
      >
        <template #icon>
          <BoldSvg />
        </template>
      </Button>

      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        :isActive="isItalicMarkActive"
        :disabled="disabled"
        @click="handleItalicMarkButtonClick"
      >
        <template #icon>
          <ItalicSvg />
        </template>
      </Button>

      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        :isActive="isStrikeMarkActive"
        :disabled="disabled"
        @click="handleStrikeMarkButtonClick"
      >
        <template #icon>
          <StrikethroughSvg />
        </template>
      </Button>

      <div class="editor__toolbar-divider" />

      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        :isActive="isBulletListActive"
        :disabled="disabled"
        @click="handleBulletListButtonClick"
      >
        <template #icon>
          <BulletListSvg />
        </template>
      </Button>

      <Button
        :type="EButtonType.ghostSecondary"
        :size="ESize.small"
        :isActive="isOrderedListActive"
        :disabled="disabled"
        @click="handleOrderedListButtonClick"
      >
        <template #icon>
          <NumbersListSvg />
        </template>
      </Button>
    </div>

    <EditorContent
      :class="[
        'editor__content',
        `editor__content_${size}`,
        {
          'editor__content_disabled': disabled,
          'editor__content_readonly': readonly
        },
        contentClassName
      ]"
      :editor="editor"
    />
  </ControlWrapper>
</template>

<script lang="ts">

import {
  defineComponent,
  PropType,
  ref,
  toRefs,
} from 'vue';
import { EditorContent } from '@tiptap/vue-3';

import BoldSvg from '@/assets/svg/16x16/bold.svg';
import ItalicSvg from '@/assets/svg/16x16/italic.svg';
import StrikethroughSvg from '@/assets/svg/16x16/strikethrough.svg';
import BulletListSvg from '@/assets/svg/16x16/bullet-list.svg';
import NumbersListSvg from '@/assets/svg/16x16/numbers-list.svg';

import { ControlWrapper, Button } from '..';
import { EButtonType, ESize } from '../types';
import { useInteractWithEditor } from './application/useInteractWithEditor';

export default defineComponent({
  name: 'Editor',
  components: {
    ControlWrapper,
    Button,
    EditorContent,
    BoldSvg,
    BulletListSvg,
    ItalicSvg,
    NumbersListSvg,
    StrikethroughSvg,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    contentClassName: {
      type: [String, Array] as PropType<string | string[]>,
      default: '',
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  emits: [
    'blur',
    'focus',
    'update:value',
    'change',
    // событие возвращает простой текст, без форматирования (без html тэгов)
    'input',
  ],
  setup(props, { emit }) {
    const {
      value,
      placeholder,
      disabled,
      readonly,
    } = toRefs(props);

    const controlWrapperRef = ref<InstanceType<typeof ControlWrapper> | null>(null);

    const {
      editor,
      isBoldMarkActive,
      isItalicMarkActive,
      isStrikeMarkActive,
      isBulletListActive,
      isOrderedListActive,

      handleBoldMarkButtonClick,
      handleItalicMarkButtonClick,
      handleStrikeMarkButtonClick,
      handleBulletListButtonClick,
      handleOrderedListButtonClick,
      focusEditor,
    } = useInteractWithEditor({
      controlWrapperRef,
      value,
      placeholder,
      isDisabled: disabled,
      isReadonly: readonly,

      emit,
    });

    return {
      controlWrapperRef,
      editor,
      EButtonType,
      ESize,
      isBoldMarkActive,
      isItalicMarkActive,
      isStrikeMarkActive,
      isBulletListActive,
      isOrderedListActive,

      handleBoldMarkButtonClick,
      handleItalicMarkButtonClick,
      handleStrikeMarkButtonClick,
      handleBulletListButtonClick,
      handleOrderedListButtonClick,
      focusEditor,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
