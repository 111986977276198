import * as types from './mutation-types';

export enum ERightPanelSection {
  executionRequestList = 'executionRequestList',
  executorsLookupsFeed = 'executorsLookupsFeed',
  orderList = 'orderList',
  operationalDocumentsList = 'operationalDocumentsList',
  referencesList = 'referencesList',
}

export type TRightPanelState = Record<ERightPanelSection, {
  isOpenRoutes?: boolean,
  isOpenRightPanel: boolean,
  rightPanelWidth: number,
}>;

export type TRightPanelMutations<S = TRightPanelState> = {
  [types.SET_IS_OPEN_ROUTES](state: S, payload: { sectionName: ERightPanelSection, isOpenRoutes: boolean }): void,
  [types.SET_IS_OPEN_RIGHT_PANEL](state: S, payload: {
    sectionName: ERightPanelSection,
    isOpenRightPanel: boolean,
  }): void,
  [types.SET_RIGHT_PANEL_WIDTH](state: S, payload: { sectionName: ERightPanelSection, rightPanelWidth: number }): void,
};
