import { InjectionKey } from 'vue';

import { TRadioGroupContext } from './types';

export enum ESize {
  small = 'small',
  large = 'large',
  medium = 'medium',
}

export enum EButtonType {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  ghostPrimary = 'ghost-primary',
  ghostSecondary = 'ghost-secondary',
  link = 'link',
}

export enum EActionSheetType {
  default = 'default',
  negative = 'negative',
  parentItem = 'parentItem',
}

export enum EHtmlType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum EInputTypes {
  text = 'text',
  email = 'email',
  search = 'search',
  tel = 'tel',
  number = 'number',
  password = 'password',
}

export enum EInputModes {
  none = 'none',
  text = 'text',
  numeric = 'numeric',
  decimal = 'decimal',
  tel = 'tel',
  email = 'email',
  search = 'search',
  url = 'url',
}

export enum ERadioButtonOptionType {
  default = 'default',
  button = 'button',
}

export enum EPopoverPlacement {
  top = 'top',
  topLeft = 'topLeft',
  topRight = 'topRight',
  left = 'left',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
  right = 'right',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
  bottom = 'bottom',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export enum EBadgeColor {
  gray = 'gray',
  red = 'red',
  volcano = 'volcano',
  orange = 'orange',
  gold = 'gold',
  lime = 'lime',
  green = 'green',
  cyan = 'cyan',
  blue = 'blue',
  geekblue = 'geekblue',
  purple = 'purple',
  magenta = 'magenta',
  white = 'white',
}

export const DROPDOWN_MARGIN = 2;
export const MAX_VISIBLE_DROPDOWN_ITEM = 10;
export const MIN_ITEM_HEIGHT = 32;
// 1 elem + padding up and down 4px;
export const MIN_DROPDOWN_HEIGHT = MIN_ITEM_HEIGHT + 8;

export const RadioGroupContextKey: InjectionKey<TRadioGroupContext> = Symbol('radioGroupContext');

export const DEFAULT_MIN_DATE = '1990-01-01';
export const DEFAULT_MAX_DATE = '2099-12-31';

export enum EDatePickerInputSection {
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum EMonth {
  january = 1,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
}

export enum EEditorNode {
  bulletList = 'bulletList',
  orderedList = 'orderedList',
}

export enum EEditorMark {
  bold = 'bold',
  italic = 'italic',
  strike = 'strike',
}
